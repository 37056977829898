import { format } from "date-fns";

export const statusBadgeText: {
  [k: string]: (_ordAccount?: string, _error_message?: string, _synced_at?: string) => string;
} = {
  SYNC_NOT_NEEDED: () => "Синхронизация не нужна",
  READY_TO_SYNC: () => "Готово к синхронизации",
  IS_SYNCHRONIZING: (ordAccount, _error_message, synced_at) =>
    `${
      synced_at ? `${format(new Date(synced_at), "dd.MM.yyyy, HH:MM:SS")} ` : ""
    }Регистрируется в ЕРИР на аккаунт ${ordAccount}.`,
  SYNCHRONIZED: (ordAccount, _error_message, synced_at) =>
    `${
      synced_at ? `${format(new Date(synced_at), "dd.MM.yyyy, HH:MM:SS")} ` : ""
    }Зарегистрировано в ЕРИР на аккаунт ${ordAccount}.`,
  SYNC_ERROR: (ordAccount, error_message, synced_at) =>
    `${
      synced_at ? `${format(new Date(synced_at), "dd.MM.yyyy, HH:MM:SS")} ` : ""
    }Ошибка регистрации в аккаунте ${ordAccount}: ${error_message}.`
};

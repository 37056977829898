import { ID } from "models";
import { BsPencil } from "react-icons/bs";
import FakeInput from "ui/FakeInput";
import IconButton from "ui/IconButton";
import Tooltip from "ui/Tooltip";

export type TContractInfo = {
  id: ID;
  number?: string;
  clientId?: ID;
  clientName?: string;
  contractorId?: ID;
  contractorName?: string;
};

type Props = {
  contract?: TContractInfo;
  toggle: () => void;
  isEditable?: boolean;
};

const ContractNumber = ({ contract, toggle, isEditable }: Props) => {
  if (!contract) return null;

  return (
    <div className="row align-items-center">
      <div className="col-sm-9 mb-3">
        <FakeInput
          title="Номер изначального договора"
          texts={[
            `Номер договора: ${contract.number || `[${contract.id}]`}`,
            `Заказчик: ${contract.clientName || `[${contract.clientId}]`}`,
            `Исполнитель: ${contract.contractorName || `[${contract.contractorId}]`}`
          ]}
          infoText={{ text: "text text", id: "contract_number_fake_input" }}
        />
      </div>
      <div className="col-sm-3 mb-3">
        {isEditable ? (
          <IconButton
            id="change-contract-button"
            outline
            isFullWidth
            text="Изменить договор"
            icon={<BsPencil size={14} />}
            onClick={toggle}
          />
        ) : (
          <Tooltip
            id="change-contract-button"
            maxWidth="20em"
            target={
              <div id="change-contract-button">
                <IconButton
                  outline
                  isFullWidth
                  text="Изменить договор"
                  icon={<BsPencil size={14} />}
                  disabled
                />
              </div>
            }
          >
            Изменение договора невозможно, так&nbsp;как по&nbsp;креативам в&nbsp;составе группы была
            подана статистика.
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default ContractNumber;

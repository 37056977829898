import { Platform } from "models";
import { TPlatformFilter } from "types/TPlatformFilter";
import { TSearchParamsPropsNoFilter } from "types/TSearchParams";
import { getSearchParams } from "utils/getSearchParams";
import { APIListData } from "./types";

export interface IPropsPlatform extends TSearchParamsPropsNoFilter {
  filter?: TPlatformFilter;
}

export const getPlatformList = (props?: IPropsPlatform): Promise<APIListData<Platform>> => {
  return fetch(
    props === undefined ? `/api/v1/platform/` : `/api/v1/platform/?${getSearchParams(props)}`,
    {
      mode: "cors",
      headers: {
        "X-Requested-With": "XMLHttpRequest"
      }
    }
  ).then((res) => {
    const result = res.json() as Promise<APIListData<Platform>>;
    return result;
  });
};

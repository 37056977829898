import { Contract, ID } from "models";
import { APIData } from "./types";

export const getContract = (id: ID): Promise<APIData<Contract>> => {
  return fetch(`/api/v1/contract/${id}/`, {
    mode: "cors",
    headers: {
      "X-Requested-With": "XMLHttpRequest"
    }
  })
    .then((res) => {
      const result = res.json() as Promise<APIData<Contract>>;
      return result;
    })
    .then((res) => {
      if (res.status === false) {
        throw new Error(res.error_message);
      }
      return res;
    });
};

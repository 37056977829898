import { getContract } from "api/contract_item";
import { APIData } from "api/types";
import { Contract, ID } from "models";
import { FieldValues, UseFormReset } from "react-hook-form";
import { useQuery } from "react-query";

const useLoadContract = (id: ID, reset: UseFormReset<FieldValues>) => {
  const { isLoading, error, data } = useQuery<APIData<Contract>, Error>({
    queryKey: ["contract_item", id],
    enabled: !!id,
    queryFn: () => getContract(id),
    onSuccess: ({
      status: contractStatus,
      data: contractData,
      error_message: contractErrorMessage
    }) => {
      if (contractStatus) {
        reset({
          ...contractData,
          vat_included:
            typeof contractData.vat_included === "boolean"
              ? +contractData.vat_included
              : contractData.vat_included,
          noNumber: contractData.contract_number ? false : true,
          self_ad_participant: contractData.client,
          is_agent_acting_for_publisher:
            typeof contractData.is_agent_acting_for_publisher === "boolean"
              ? +contractData.is_agent_acting_for_publisher
              : null
        });
      } else {
        console.log(contractErrorMessage);
      }
    }
  });

  return {
    isLoading,
    error,
    initialContractsIds: data?.data?.initial_contracts,
    searchClientParticipantsValueDefault: data?.data.extra_fields?.client?.name,
    searchContractorParticipantsValueDefault: data?.data.extra_fields?.contractor?.name,
    status: (data?.data.status === 2 ? "draft" : "active") as "draft" | "active"
  };
};

export default useLoadContract;

import { useState } from "react";
import { useMutation } from "react-query";
import { PredictiveSearchField } from "ui/PredictiveSearch";
import { searchMutationContract } from "ui/PredictiveSearch/helpers";
import getErrorText from "utils/getErrorText";
import { FieldProps } from "./types";

const ParentContractField = ({
  control,
  errors,
  contractLoading,
  isShown,
  isRequired
}: FieldProps) => {
  const [searchParentContractValue, setSearchParentContractValue] = useState<string>();

  const {
    mutate: parentContractSearchRequest,
    isLoading: parentContractSearchLoading,
    data: parentContractSearchResponse
  } = useMutation({
    mutationFn: searchMutationContract(searchParentContractValue)
  });

  if (!isShown) return null;

  return (
    <div className="row align-items-center">
      <div className="col-sm-9">
        <PredictiveSearchField
          name="parent_contract"
          title="Выберите договор к которому относится ДС"
          optionKey={["contract_number", "amount", "status"]}
          optionKeyLabels={["Номер", "Сумма", "hidden"]}
          inputValue={searchParentContractValue}
          setInputValue={setSearchParentContractValue}
          control={control}
          errors={errors}
          errorText={getErrorText(errors, "client", "Должно быть заполнено.")}
          isLoading={parentContractSearchLoading}
          request={parentContractSearchRequest}
          response={parentContractSearchResponse}
          disabled={contractLoading}
          optionDisableCondition={{ status: 2 }}
          infoText="Нельзя выбрать договоры, статус которых &lsquo;ЧЕРНОВИК&rsquo;"
          isRequired={isRequired !== null ? isRequired : false}
          isStared={isRequired !== null}
        />
      </div>
    </div>
  );
};

export default ParentContractField;

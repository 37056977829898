import cn from "classnames";
import EACSOptions from "constants/EACSOptions";
import { format } from "date-fns";
import creative_form from "dictionaries/creative_form.json";
import creative_status from "dictionaries/creative_status.json";
import creative_type from "dictionaries/creative_type.json";
import { BsPencil } from "react-icons/bs";
import { Table } from "reactstrap";
import IconButton from "ui/IconButton";
import Tooltip from "ui/Tooltip";
import getTitleFromDict from "utils/getTitleFromDict";
import { TCreativeAttributesElementProps } from "../../types";
import { statusBadgeText } from "./constants";

const AttributesBody = ({
  attributes,
  toggleChangeContractModal,
  toggleChangeGroupModal,
  isContractEditable
}: TCreativeAttributesElementProps) => {
  if (attributes === "loading") return <p>Загрузка...</p>;

  if (attributes === "error") return <p>Произошла ошибка загрузки</p>;

  return (
    <Table borderless hover className="mb-0">
      <tbody>
        <tr>
          <td>Изначальный договор</td>
          <td>{attributes.initial_contract?.title || attributes.initial_contract?.id || "-"}</td>
          <td>
            {isContractEditable ? (
              <IconButton
                id="change-contract-button"
                outline
                isFullWidth
                text="Изменить договор"
                icon={<BsPencil size={14} />}
                onClick={toggleChangeContractModal}
                isNoWrap
              />
            ) : (
              <Tooltip
                id="change-contract-button"
                maxWidth="20em"
                target={
                  <div id="change-contract-button">
                    <IconButton
                      outline
                      isFullWidth
                      text="Изменить договор"
                      icon={<BsPencil size={14} />}
                      isNoWrap
                      disabled
                    />
                  </div>
                }
              >
                Изменение договора невозможно, так&nbsp;как по&nbsp;креативам в&nbsp;составе группы
                была подана статистика.
              </Tooltip>
            )}
          </td>
        </tr>
        <tr>
          <td>Группа креативов</td>
          <td>{attributes.group?.label}</td>
          <td>
            <IconButton
              outline
              isFullWidth
              text="Сменить группу"
              icon={<BsPencil size={14} />}
              onClick={toggleChangeGroupModal}
              isNoWrap
            />
          </td>
        </tr>
        <tr>
          <td>Токен</td>
          <td>{attributes.marker || "-"}</td>
          <td></td>
        </tr>
        <tr>
          <td>Тип рекламной кампании</td>
          <td>{getTitleFromDict(attributes.advertisement_type, creative_type)}</td>
          <td></td>
        </tr>
        <tr>
          <td>Форма распространения</td>
          <td>{getTitleFromDict(attributes.advertisement_form, creative_form)}</td>
          <td></td>
        </tr>
        <tr>
          <td>Дата начала периода размещения</td>
          <td>
            {attributes.start_date ? format(new Date(attributes.start_date), "dd.MM.yyyy") : "-"}
          </td>
          <td></td>
        </tr>
        <tr>
          <td>Дата окончания периода размещения</td>
          <td>{attributes.end_date ? format(new Date(attributes.end_date), "dd.MM.yyyy") : "-"}</td>
          <td></td>
        </tr>
        <tr>
          <td>Особенности рекламной кампании</td>
          <td>{attributes.peculiarities}</td>
          <td></td>
        </tr>
        <tr>
          <td>Коды ОКВЭД</td>
          <td>
            {attributes.eacs
              ?.map((eac) => EACSOptions.find(({ value }) => value === eac)?.label)
              .join(", ")}
          </td>
          <td></td>
        </tr>
        <tr>
          <td>Параметры целевой аудитории</td>
          <td>{attributes.target_audience}</td>
          <td></td>
        </tr>
        <tr>
          <td>Общее описание объекта рекламирования</td>
          <td>{attributes.description}</td>
          <td></td>
        </tr>
        <tr>
          <td>Статус</td>
          <td>{getTitleFromDict(attributes.status, creative_status)}</td>
          <td></td>
        </tr>
        {attributes.sync_status && attributes.sync_status.length ? (
          <tr>
            <td>Ответ ЕРИР</td>
            <td>
              {attributes.sync_status.map((syncStatus) => (
                <p
                  key={syncStatus.synced_at}
                  className={cn("mb-0", {
                    "text-danger": syncStatus.status === "SYNC_ERROR",
                    "text-success": syncStatus.status === "SYNCHRONIZED"
                  })}
                >
                  {statusBadgeText[syncStatus.status](
                    syncStatus.ord,
                    syncStatus.error_message,
                    syncStatus.synced_at
                  )}
                </p>
              ))}
            </td>
            <td></td>
          </tr>
        ) : null}
      </tbody>
    </Table>
  );
};

export default AttributesBody;

import { getPlatformList } from "api/platform_list";
import { APIListData } from "api/types";
import uniq from "lodash/uniq";
import { ID, Platform } from "models";
import { useRef, useState } from "react";
import { useQuery } from "react-query";

type TOptions = {
  value: string | number;
  label: string;
}[];

const isString = (item: string | undefined): item is string => {
  return !!item;
};

const useLoadAllUrls = (id?: ID) => {
  const [options, setOptions] = useState<TOptions>([]);
  const defaultOptions = useRef<TOptions>([]);

  useQuery<APIListData<Platform>, Error>({
    queryKey: ["platform_list_urls", id],
    queryFn: () =>
      getPlatformList({ page: 0, size: 1_000_000, filter: { platform_url__isempty: false } }),
    onSuccess: (res) => {
      const arrayOfUrls = uniq(res.data.results.map((p) => p.platform_url).filter(isString));
      const newOptions = arrayOfUrls.map((url) => ({ value: url, label: url }));
      setOptions(newOptions);
      defaultOptions.current = newOptions;
    }
  });

  return { options, setOptions, defaultOptions: defaultOptions.current };
};

export default useLoadAllUrls;

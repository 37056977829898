import difference from "lodash/difference";
import { ReactNode } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import IExportRelatedProps from "types/IExportRelatedProps";

interface Props extends IExportRelatedProps {
  children: ReactNode;
}

const ListingCardsWrapper = ({ children, pageIds, exportIds, setExportIds }: Props) => (
  <div className="mb-0 d-block d-sm-none">
    {setExportIds && exportIds && pageIds ? (
      <FormGroup>
        <Input
          type="checkbox"
          onChange={() => {
            difference(pageIds, exportIds).length !== 0
              ? setExportIds((prev) => Array.from(new Set([...pageIds, ...prev])))
              : setExportIds((prev) => prev.filter((item) => !pageIds.includes(item)));
          }}
          disabled={!(!!setExportIds && pageIds && exportIds)}
          checked={pageIds && exportIds && difference(pageIds, exportIds).length === 0}
        />{" "}
        <Label check for="page">
          Выбрать все записи на этой странице
        </Label>
      </FormGroup>
    ) : null}
    <div>{children}</div>
  </div>
);

export default ListingCardsWrapper;

import { ErrorMessage } from "Components/ErrorMessage";
import { deleteCreativeNew } from "api/creative_new_delete";
import { getCreativeNewItem } from "api/creative_new_item";
import { putCreativeNew } from "api/creative_new_put";
import { APIData } from "api/types";
import { AxiosError } from "axios";
import { format } from "date-fns";
import { ID, NEW_Creative, NEW_CreativeGroup } from "models";
import { memo, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import { StateProps } from "reducers";
import {
  setCloseModalDestination,
  setCloseModalNeeded,
  setCloseModalOpen
} from "reducers/CloseModalSettings";
import onDeleteMutation from "utils/onDeleteMutation";
import onErrorMutation from "utils/onErrorMutation";
import onSuccessMutation from "utils/onSuccessMutation";
import useIsOpenControl from "utils/useIsOpenControl";
import ChangeContractModal from "../ChangeContractModal";
import ChangeGroupModal from "../ChangeGroupModal";
import CreativeAttributes from "./components/attributes";
import CreativeForm from "./components/form";
import editCreativeFunction from "./helpers/editCreativeFunction";
import onCreativeLoadSuccess from "./helpers/onCreativeLoadSuccess";
import { TCreativeAttributes, TCreativeEditFormProps, TDefaultValuesCreativeEditor } from "./types";

const CreativeEditForm = memo(function CEF({ id }: TCreativeEditFormProps) {
  const dispatch = useDispatch();
  const creativeLastSearch = useSelector((state: StateProps) => state.LastSearchParams.creative);
  const listingLink = `/creative/${creativeLastSearch}`;
  const closeModalIsNeeded = useSelector((state: StateProps) => state.CloseModalSettings.isNeeded);
  const [isDraft, setIsDraft] = useState(false);
  const [attributes, setAttributes] = useState<TCreativeAttributes>("loading");
  const [groupId, setGroupId] = useState<ID>();
  const [groupInfo, setGroupInfo] = useState<NEW_CreativeGroup>();
  const [isContractEditable, setIsContractEditable] = useState(true);

  const { isOpen: isChangeContractModalOpen, toggle: toggleChangeContractModal } =
    useIsOpenControl();
  const { isOpen: isChangeGroupModalOpen, toggle: toggleChangeGroupModal } = useIsOpenControl();

  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
    setValue,
    setError,
    clearErrors
  } = useForm({
    defaultValues: {} as TDefaultValuesCreativeEditor
  });

  const {
    isLoading: creativeLoading,
    error: creativeError,
    data: creative,
    refetch: creativeRefetch
  } = useQuery<APIData<NEW_Creative>, Error>({
    queryKey: ["creative_item", id],
    queryFn: () => getCreativeNewItem(id),
    onSuccess: (creative) =>
      onCreativeLoadSuccess({
        status: creative.status,
        creative: creative.data,
        reset,
        setIsDraft,
        setAttributes,
        setGroupId,
        setGroupInfo,
        setIsContractEditable
      })
  });

  const { mutate: edit } = useMutation({
    mutationFn: putCreativeNew,
    onSuccess: (p) => onSuccessMutation(p, listingLink, navigate, false, `Креатив [${id}] изменен`),
    onError: (err) => onErrorMutation(err as AxiosError, setError)
  });

  const { mutate: onCreativeRemove } = useMutation({
    mutationFn: deleteCreativeNew,
    onSuccess: (response) =>
      onDeleteMutation({
        response,
        href: listingLink,
        navigate,
        successMessage: `Креатив [${id}] удален`
      })
  });

  const onClose = () => {
    if (closeModalIsNeeded) {
      dispatch(setCloseModalOpen());
      dispatch(setCloseModalDestination(listingLink));
    } else {
      navigate(listingLink);
    }
  };

  const onFormChange = () => (!closeModalIsNeeded ? dispatch(setCloseModalNeeded()) : null);

  const onSubmit = handleSubmit(
    () => {
      editCreativeFunction({ id, isDraft, watch, creative, groupInfo, edit });
    },
    (err) => {
      console.log(err);
    }
  );

  if (creativeError)
    return (
      <ErrorMessage
        error={creativeError?.message || "Не указана Форма распространения"}
        isEmbedded
      />
    );

  const creativeTitle = creative?.data.title || `[${id}]`;
  const buttonsDisabled = watch("attachments")
    ?.map(({ fileState }) => fileState)
    .includes("LOADING");

  return (
    <>
      <h1>Редактирование креатива {creativeTitle}</h1>

      <CreativeAttributes
        attributes={attributes}
        toggleChangeContractModal={toggleChangeContractModal}
        toggleChangeGroupModal={toggleChangeGroupModal}
        isContractEditable={isContractEditable}
      />

      <CreativeForm
        control={control}
        errors={errors}
        onSubmit={onSubmit}
        onFormChange={onFormChange}
        watch={watch}
        creativeLoading={creativeLoading}
        setValue={setValue}
        clearErrors={clearErrors}
        attributes={attributes}
        isDraft={isDraft}
        creativeId={id}
        buttons={
          <div className="d-flex flex-column flex-md-row justify-content-between gap-3 mt-3">
            <div className="d-flex gap-3">
              {isDraft ? (
                <>
                  <Button
                    type="button"
                    color="danger"
                    value="delete"
                    onClick={() => onCreativeRemove(id)}
                    disabled={buttonsDisabled}
                  >
                    Удалить
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    outline
                    value={2}
                    onClick={() => setIsDraft(true)}
                    disabled={buttonsDisabled}
                  >
                    Сохранить черновик
                  </Button>
                </>
              ) : null}
            </div>
            <div className="d-flex gap-3">
              <Button
                type="button"
                color="primary"
                outline
                onClick={onClose}
                disabled={buttonsDisabled}
              >
                Закрыть
              </Button>
              <Button
                type="submit"
                color="danger"
                value={1}
                onClick={() => setIsDraft(false)}
                disabled={buttonsDisabled}
              >
                Отправить в ЕРИР
              </Button>
            </div>
          </div>
        }
      />

      {groupId ? (
        <ChangeContractModal
          isOpen={isChangeContractModalOpen}
          toggle={toggleChangeContractModal}
          groupId={groupId}
          onSubmit={({ initial_contract, final_contract }, initialContractInfo) => {
            setGroupInfo((prev) =>
              prev
                ? {
                    ...prev,
                    initial_contract,
                    final_contract
                  }
                : undefined
            );
            toggleChangeContractModal();
            onFormChange();

            let title = initialContractInfo?.contract_number || `[${initial_contract}]`;
            if (initialContractInfo?.contract_date)
              title += ` от ${format(new Date(initialContractInfo.contract_date), "dd.MM.yyyy")}`;

            setAttributes((prev) =>
              prev !== "loading" && prev !== "error"
                ? {
                    ...prev,
                    initial_contract: { id: initial_contract, title }
                  }
                : prev
            );
          }}
        />
      ) : null}

      <ChangeGroupModal
        isOpen={isChangeGroupModalOpen}
        toggle={toggleChangeGroupModal}
        creativeTitle={creativeTitle}
        isDraft={isDraft}
        creativeId={id}
        groupId={groupId}
        refetch={creativeRefetch}
      />
    </>
  );
});

export default CreativeEditForm;

import isArray from "lodash/isArray";
import { Fragment } from "react";
import { components, OptionProps } from "react-select";

const MultiFieldOption = (
  props: OptionProps<any>,
  optionKey: string | string[],
  optionKeyLabels?: string[]
  // highlight if wrong
) => {
  const parsed = JSON.parse(props.data.label);
  return (
    <components.Option {...props}>
      {isArray(optionKey) &&
        optionKey.map((op, index) =>
          optionKeyLabels && optionKeyLabels[index] === "hidden" ? (
            <Fragment key={op}></Fragment>
          ) : (
            <p key={op} className="mb-0">
              {optionKeyLabels ? (
                <span className="text-secondary">{optionKeyLabels[index]}: </span>
              ) : null}
              <span>{parsed[op]}</span>
            </p>
          )
        )}
    </components.Option>
  );
};

export default MultiFieldOption;

import get from "lodash/get";
import { ReactElement } from "react";
import { Control, Controller, FieldErrors, FieldValues, Validate } from "react-hook-form";
import { FormGroup, Input } from "reactstrap";
import FieldFeedback from "ui/FieldFeedback";
import FieldLabel from "ui/FieldLabel";

type Props = {
  name: string;
  title?: string;
  control: Control<FieldValues, any>;
  errors: FieldErrors<FieldValues>;
  isRequired?: boolean;
  isStared?: boolean;
  errorText?: string;
  disabled?: boolean;
  infoText?: string | ReactElement;
  pattern?: RegExp;
  maxLength?: number;
  minLength?: number;
  validate?: Validate<any, FieldValues> | Record<string, Validate<any, FieldValues>>;
  extraAction?: (_?: string) => void;
};

export const TextAreaField = ({
  name,
  title,
  control,
  errors,
  isRequired,
  isStared,
  errorText,
  disabled,
  infoText,
  pattern,
  maxLength,
  minLength,
  validate,
  extraAction
}: Props) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: isRequired,
        pattern,
        maxLength,
        minLength,
        validate
      }}
      render={({ field: { value, onChange, onBlur } }) => (
        <FormGroup>
          {title ? (
            <FieldLabel
              title={title}
              for={name}
              isRequired={isRequired || isStared}
              info={
                infoText
                  ? {
                      text: infoText,
                      id: `info_${name}`
                    }
                  : undefined
              }
            />
          ) : null}
          <Input
            id={name}
            placeholder={title}
            value={(value as string) || ""}
            onChange={(e) => {
              onChange(e);
              extraAction && extraAction(e.target.value);
            }}
            type="textarea"
            invalid={!!errors && !!get(errors, name)}
            disabled={disabled}
            onBlur={onBlur}
            maxLength={maxLength}
          />
          <FieldFeedback name={name} errors={errors} errorText={errorText} />
        </FormGroup>
      )}
    />
  );
};

import { getContract } from "api/contract_item";
import { format } from "date-fns";
import { ID } from "models";
import { TAttachmentType } from "types/AttachmentsTypes";
import { v4 as uuidv4 } from "uuid";
import { TCreativeAttributes, TOnCreativeLoadSuccessProps } from "../types";

const onCreativeLoadSuccess = ({
  status,
  creative,
  reset,
  setIsDraft,
  setAttributes,
  setGroupId,
  setGroupInfo,
  setIsContractEditable
}: TOnCreativeLoadSuccessProps) => {
  if (status && creative) {
    const attachmentsMedia =
      creative.media
        ?.map((item) => ({
          ...item,
          type: "file" as TAttachmentType,
          fileState: "SUCCESS",
          tempId: uuidv4()
        }))
        .sort((a, b) => {
          if (a.id && b.id) return +a.id - +b.id;
          return -1;
        }) || [];

    const attachmentsText =
      creative.texts
        ?.map((item) => ({
          ...item,
          type: "text" as TAttachmentType,
          tempId: uuidv4()
        }))
        .sort((a, b) => {
          if (a.id && b.id) return +a.id - +b.id;
          return -1;
        }) || [];

    reset({
      advertiser_urls: creative.advertiser_urls?.map((advertiser_url) => ({
        advertiser_url
      })),
      media_description: "",
      texts: creative.texts,
      media: creative.media,
      group_id: creative.group as ID,
      no_urls: !creative.advertiser_urls || creative.advertiser_urls.length < 1,
      attachments: [...attachmentsMedia, ...attachmentsText]
    });
    setIsDraft(creative.status === 2);

    const initialContractId = creative.extra_fields?.group?.initial_contract;

    let peculiarities = "-";
    if (creative.extra_fields?.group?.is_native) peculiarities = "Нативная";
    if (creative.extra_fields?.group?.is_social) peculiarities = "Социальная";

    // Расчет атрибутов

    const newAttributes: TCreativeAttributes = {
      initial_contract: {
        id: initialContractId
      },
      group: {
        id: creative.group as ID,
        label: creative.extra_fields?.group?.label
      },
      marker: creative.marker,
      advertisement_form: creative.extra_fields?.group?.advertisement_form,
      advertisement_type: creative.extra_fields?.group?.advertisement_type,
      start_date: creative.extra_fields?.group?.start_date,
      end_date: creative.extra_fields?.group?.end_date,
      peculiarities,
      eacs: creative.extra_fields?.group?.eacs,
      target_audience: creative.extra_fields?.group?.target_audience,
      description: creative.extra_fields?.group?.description,
      status: creative.status,
      sync_status: creative.extra_fields?.sync_status
    };

    if (initialContractId) {
      getContract(initialContractId)
        .then((c) => {
          if (c.status) {
            const contract = c.data;

            let title = contract.contract_number || `[${contract.id}]`;
            if (contract.contract_date)
              title += ` от ${format(new Date(contract.contract_date), "dd.MM.yyyy")}`;

            newAttributes.initial_contract!.title = title;
            setAttributes(newAttributes);
          } else {
            setAttributes(newAttributes);
          }
        })
        .catch(() => setAttributes(newAttributes));
    } else {
      setAttributes(newAttributes);
    }

    setGroupId(creative.group as ID);

    const groupInfo = creative.extra_fields?.group;
    setGroupInfo(groupInfo);

    const creatives = groupInfo?.creatives;

    if (creatives && creatives.length) {
      let isAnyCreativeSync = false;
      let i = 0;
      do {
        isAnyCreativeSync = creatives[i].is_statistics_sent || false;
        i++;
      } while (!isAnyCreativeSync && i < creatives.length);
      setIsContractEditable(!isAnyCreativeSync && creative.status === 2);
    }
  }
};

export default onCreativeLoadSuccess;

import { getPlatform } from "api/platform_item";
import { APIData } from "api/types";
import { ID, Platform } from "models";
import { Dispatch, SetStateAction } from "react";
import { FieldValues, UseFormReset } from "react-hook-form";
import { useQuery } from "react-query";

const useLoadPlatform = (
  id: ID,
  reset: UseFormReset<FieldValues>,
  setIsDraft: Dispatch<SetStateAction<boolean>>
) => {
  const {
    isLoading,
    error,
    data: platform
  } = useQuery<APIData<Platform>, Error>({
    queryKey: ["platform_list", id],
    queryFn: () => getPlatform(id),
    onSuccess: ({ status, data, error_message }) => {
      if (status) {
        reset({
          ...data,
          no_url: data && data.platform_url !== "" ? false : true,
          is_owner: !!data.owner
        });
        setIsDraft(data.status === 2);
      } else {
        console.log(error_message);
      }
    }
  });

  return {
    isLoading,
    error,
    searchParticipantsValueDefault: platform?.data?.extra_fields?.platform_owner_name,
    searchPlatformsValueDefault: platform?.data.platform_url
  };
};

export default useLoadPlatform;

import { getParticipantList } from "api/participant_list";
import { useState } from "react";
import { useMutation } from "react-query";
import { PredictiveSearchField } from "ui/PredictiveSearch";
import getErrorText from "utils/getErrorText";
import { FieldProps } from "./types";

const SelfAdParticipantField = ({
  control,
  errors,
  contractLoading,
  watch,
  isShown,
  isRequired
}: FieldProps) => {
  const [searchParticipantValue, setSearchParticipantValue] = useState<string | undefined>(
    undefined
  );

  const {
    mutate: participantsRequest,
    isLoading: participantsLoading,
    data: participantsResponse
  } = useMutation({
    mutationFn: () => {
      const clientId = watch("client") as number | undefined;
      return getParticipantList(
        searchParticipantValue
          ? { search: searchParticipantValue }
          : { filter: clientId ? { id: [clientId] } : {} }
      );
    }
  });

  if (!isShown) return null;

  return (
    <div className="row align-items-center">
      <div className="col-sm-9">
        <PredictiveSearchField
          name="self_ad_participant"
          title="Контрагент саморекламы"
          optionKey={["name", "inn", "status"]}
          optionKeyLabels={["Название", "ИНН", "hidden"]}
          inputValue={searchParticipantValue}
          setInputValue={setSearchParticipantValue}
          control={control}
          errors={errors}
          errorText={getErrorText(errors, "contractor", "Должно быть заполнено.")}
          isLoading={participantsLoading}
          request={participantsRequest}
          response={participantsResponse}
          disabled={contractLoading}
          optionDisableCondition={{ status: 2 }}
          infoText="Нельзя выбрать контрагентов, статус которых &lsquo;ЧЕРНОВИК&rsquo;"
          isRequired={isRequired !== null ? isRequired : false}
          isStared={isRequired !== null}
        />
      </div>
    </div>
  );
};

export default SelfAdParticipantField;

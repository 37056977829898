import * as Sentry from "@sentry/react";
import { createRoot } from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { HashRouter } from "react-router-dom";
import "./assets/base.scss";
import configureStore from "./config/configureStore";
import Main from "./Pages/MainPage";
import "./polyfills";
import * as serviceWorker from "./serviceWorker";

const store = configureStore();
const container = document.getElementById("root")!;
const root = createRoot(container);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false
    }
  }
});

// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
const sentryDSN = ((window as any).SENTRY_DSN as string) || "";
// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
const tracePropagationTargets = ((window as any).TRACE_PROPAGATION_TARGETS as [string]) || [];

if (sentryDSN !== "") {
  Sentry.init({
    dsn: sentryDSN,
    integrations: [Sentry.browserTracingIntegration()],
    tracePropagationTargets,
    tracesSampleRate: 1.0 // Capture 100% of the transactions
  });
}

root.render(
  <Sentry.ErrorBoundary>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <HashRouter>
          <Main />
        </HashRouter>
      </Provider>
    </QueryClientProvider>
  </Sentry.ErrorBoundary>
);

serviceWorker.unregister();

import { ErrorMessage } from "Components/ErrorMessage";
import { ParticipantFormDV } from "constants/defaultValues/ParticipantFormDV";
import { ID } from "models";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import { StateProps } from "reducers";
import { scrollToError } from "utils/scrollToError";
import { ParticipantForm } from "./components/form";
import useDeleteParticipant from "./hooks/useDeleteParticipant";
import useEditParticipant from "./hooks/useEditParticipant";
import useLoadParticipant from "./hooks/useLoadParticipant";

type Props = {
  id: ID;
};

const ParticipantEditForm = ({ id }: Props) => {
  const participantLastSearch = useSelector(
    (state: StateProps) => state.LastSearchParams.participant
  );
  const [isDraft, setIsDraft] = useState(false);

  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
    setError
  } = useForm({ defaultValues: ParticipantFormDV });

  const { isLoading, error, status } = useLoadParticipant(id, reset);
  const edit = useEditParticipant(participantLastSearch, watch, setError);
  const del = useDeleteParticipant(participantLastSearch, watch);

  const onSubmit = handleSubmit(
    (_, event) => {
      const submitter = (event?.nativeEvent as SubmitEvent).submitter as HTMLButtonElement;
      if (submitter.value) {
        const status = submitter.value as 1 | 2 | "delete";
        if (status === "delete") {
          del(id);
        } else {
          edit({
            id,
            participant: {
              ...watch(),
              status
            }
          });
        }
      }
    },
    (err) => {
      if (err) {
        scrollToError(err);
      }
    }
  );

  if (error) {
    return <ErrorMessage error={error.message} isEmbedded />;
  }

  const buttons = {
    draft: (
      <div className="d-flex gap-3 justify-content-between">
        <div className="d-flex gap-3">
          <Button
            type="submit"
            color="danger"
            disabled={isLoading}
            value="delete"
            onClick={() => setIsDraft(true)}
          >
            Удалить
          </Button>
          <Button
            type="submit"
            color="primary"
            disabled={isLoading}
            value={2}
            onClick={() => setIsDraft(true)}
          >
            Сохранить
          </Button>
        </div>
        <div className="d-flex gap-3">
          <Button
            href={`#/participant/${participantLastSearch}`}
            tag="a"
            color="primary"
            outline
            disabled={isLoading}
          >
            Отменить
          </Button>
          <Button
            type="submit"
            color="primary"
            disabled={isLoading}
            value={1}
            onClick={() => setIsDraft(false)}
          >
            Добавить
          </Button>
        </div>
      </div>
    ),
    active: (
      <div className="d-flex gap-3 justify-content-between">
        <div className="d-flex gap-3">
          {/* <Button type="submit" color="danger" disabled={isLoading} value={3}>
            Архивировать
          </Button> */}
        </div>
        <div className="d-flex gap-3">
          <Button
            href={`#/participant/${participantLastSearch}`}
            tag="a"
            color="primary"
            outline
            disabled={isLoading}
          >
            Отменить
          </Button>
          <Button type="submit" color="primary" disabled={isLoading} value={1}>
            Сохранить
          </Button>
        </div>
      </div>
    )
  };

  return (
    <div>
      <h1>Редактирование контрагента {watch("name")}</h1>
      <ParticipantForm
        control={control}
        errors={errors}
        onSubmit={onSubmit}
        buttons={buttons[status]}
        watch={watch}
        isLoading={isLoading}
        isDraft={isDraft}
      />
    </div>
  );
};

export default ParticipantEditForm;

import { TCreativeFilter } from "types/TCreativeFilter";
import { TSearchParamsRequired } from "types/TSearchParams";

export const defaultSearchParams: TSearchParamsRequired<TCreativeFilter> = {
  search: "",
  page: 0,
  page_size: 10,
  ordering: "-id",
  filter: {
    marker: undefined,
    ord_account: [],
    combined_status: [],
    initial_contract__client: [],
    start_date: undefined,
    end_date: undefined,
    advertisement_form: [],
    advertisement_type: [],
    contract: []
  }
};

export const defaultExportLink = "/api/v1/creative/export/xls";

import ListingLayout from "Layout/ListingLayout";
import { getContractList } from "api/contract_list";
import { getCreativeNewList } from "api/creative_new_list";
import { APIListData } from "api/types";
import Cookies from "js-cookie";
import uniq from "lodash/uniq";
import { Contract, ID, NEW_Creative } from "models";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setCloseModalNotNeeded } from "reducers/CloseModalSettings";
import { setCreativeLastSearchParams } from "reducers/LastSearchParams";
import { TCreativeFilter } from "types/TCreativeFilter";
import createNotification from "ui/Notification";
import { OrderingVariant } from "ui/SortSelector";
import getLastPageNumber from "utils/getLastPageNumber";
import getNumberArrayFromSearchParam from "utils/getNumberArrayFromSearchParam";
import getOnlyValidObj from "utils/getOnlyValidObj";
import getSearchParams from "utils/getSearchParams";
import getStringFromSearchParam from "utils/getStringFromSearchParam";
import useIsOpenControl from "utils/useIsOpenControl";
import { CreativeFilter } from "./components/filter";
import { CreativeListingActions } from "./components/listingActions";
import { CreativeTable } from "./components/table";
import { defaultExportLink, defaultSearchParams } from "./constants";

const orderVariants: OrderingVariant[] = [
  {
    order: "id",
    title: "ID ↑"
  },
  {
    order: "-id",
    title: "ID ↓"
  },
  {
    order: "title",
    title: "Наименование ↑"
  },
  {
    order: "-title",
    title: "Наименование ↓"
  },
  {
    order: "marker",
    title: "Токен ↑"
  },
  {
    order: "-marker",
    title: "Токен ↓"
  }
];

export const CreativeList = () => {
  const navigate = useNavigate();
  const { pathname, search: searchProperties } = useLocation();
  const dispatch = useDispatch();
  dispatch(setCloseModalNotNeeded());

  const [searchParams, setSearchParams] = useState(defaultSearchParams);
  const [contractIds, setContractIds] = useState<ID[]>([]);

  const { isOpen: isFilterOpen, toggle: toggleFilter } = useIsOpenControl();

  const [exportIds, setExportIds] = useState<ID[]>([]);
  const [exportLink, setExportLink] = useState<string>(defaultExportLink);

  const onExportIdCheck = (checkedId: ID) => {
    setExportIds((prev) =>
      prev.includes(checkedId) ? prev.filter((item) => item !== checkedId) : [...prev, checkedId]
    );
  };

  const {
    isLoading: creativesLoading,
    error: creativesError,
    data: creativesInfoList,
    refetch: creativesRefetch
  } = useQuery<APIListData<NEW_Creative>, Error>({
    queryKey: [
      "creative_list",
      searchParams.page,
      searchParams.page_size,
      searchParams.search,
      searchParams.filter,
      searchParams.ordering
    ],
    queryFn: () =>
      getCreativeNewList({
        page: searchParams.page,
        size: searchParams.page_size,
        search: searchParams.search,
        filter: searchParams.filter,
        ordering: searchParams.ordering
      }),
    refetchOnWindowFocus: false,
    onSuccess: (response) => {
      if (response?.status) {
        const newContractIds: ID[] = [];
        response.data.results.forEach((cr) => {
          cr.extra_fields?.group?.final_contract &&
            newContractIds.push(cr.extra_fields.group.final_contract);
          cr.extra_fields?.group?.initial_contract &&
            newContractIds.push(cr.extra_fields.group.initial_contract);
        });
        setContractIds(uniq(newContractIds));
      }
    }
  });

  const {
    isLoading: contractsLoading,
    error: contractsError,
    data: contractsInfoList
  } = useQuery<APIListData<Contract>, Error>({
    queryKey: ["creative_group_list", contractIds],
    queryFn: () =>
      getContractList({
        filter: {
          id: contractIds
        }
      }),
    refetchOnWindowFocus: false
  });

  const onPageChange = (page: number) => {
    navigate({
      pathname,
      search: queryString.stringify(
        getOnlyValidObj({
          ...searchParams.filter,
          search: searchParams.search,
          page_size: searchParams.page_size,
          ordering: searchParams.ordering,
          page: page + 1
        })
      )
    });
  };

  const onSearchClick = (query: string) => {
    navigate({
      pathname,
      search: queryString.stringify(
        getOnlyValidObj({
          ...searchParams.filter,
          page_size: searchParams.page_size,
          ordering: searchParams.ordering,
          search: query ? query : undefined,
          page: 1
        })
      )
    });
  };

  const onPageSizeSelect = (page_size: number) => {
    Cookies.set("pageSizeCreative", page_size.toString());
    const lastPageNumber = getLastPageNumber(creativesInfoList?.data?.count || 0, page_size);
    const page = lastPageNumber < searchParams.page ? (lastPageNumber || 1) - 1 : searchParams.page;
    navigate({
      pathname,
      search: queryString.stringify(
        getOnlyValidObj({
          ...searchParams.filter,
          search: searchParams.search,
          ordering: searchParams.ordering,
          page,
          page_size
        })
      )
    });
  };

  const onOrderSelect = (ordering: string) => {
    navigate({
      pathname,
      search: queryString.stringify(
        getOnlyValidObj({
          ...searchParams.filter,
          search: searchParams.search,
          page_size: searchParams.page_size,
          page: 1,
          ordering
        })
      )
    });
  };

  const onFilterClick = (filter: TCreativeFilter) => {
    navigate({
      pathname,
      search: queryString.stringify(
        getOnlyValidObj({
          search: searchParams.search,
          page_size: searchParams.page_size,
          ordering: searchParams.ordering,
          page: 1,
          ...filter
        })
      )
    });
  };

  const onExport = () => {
    if (exportIds.length) {
      setExportLink(`${defaultExportLink}?${getSearchParams({ filter: { id: exportIds } })}`);
    } else {
      createNotification("warning", "Ничего не выбрано, в файл попадут все данные", 3000);
    }
  };

  const onResetFilter = () => {
    navigate({
      pathname,
      search: queryString.stringify(
        getOnlyValidObj({
          ...defaultSearchParams.filter,
          search: searchParams.search,
          page_size: searchParams.page_size,
          ordering: searchParams.ordering,
          page: 1
        })
      )
    });
  };

  useEffect(() => {
    const cookiePageSize = Cookies.get("pageSizeCreative");
    const newSearchParams = queryString.parse(searchProperties);
    const page = newSearchParams.page ? +newSearchParams.page - 1 : defaultSearchParams.page;
    const search = newSearchParams.search
      ? (newSearchParams.search as string)
      : defaultSearchParams.search;
    const ordering = newSearchParams.ordering
      ? (newSearchParams.ordering as string)
      : defaultSearchParams.ordering;
    const page_size = newSearchParams.page_size
      ? +newSearchParams.page_size
      : cookiePageSize
        ? +cookiePageSize
        : defaultSearchParams.page_size;
    const ord_account = newSearchParams.ord_account
      ? getNumberArrayFromSearchParam(newSearchParams.ord_account)
      : defaultSearchParams.filter?.ord_account;
    const combined_status = newSearchParams.combined_status
      ? getNumberArrayFromSearchParam(newSearchParams.combined_status)
      : defaultSearchParams.filter?.combined_status;
    const start_date = newSearchParams.start_date
      ? getStringFromSearchParam(newSearchParams.start_date)
      : defaultSearchParams.filter?.start_date;
    const end_date = newSearchParams.end_date
      ? getStringFromSearchParam(newSearchParams.end_date)
      : defaultSearchParams.filter?.end_date;
    const contract = newSearchParams.contract
      ? getNumberArrayFromSearchParam(newSearchParams.contract)
      : defaultSearchParams.filter?.contract;
    const marker = newSearchParams.marker
      ? getStringFromSearchParam(newSearchParams.marker)
      : defaultSearchParams.filter?.marker;
    const initial_contract__client = newSearchParams.initial_contract__client
      ? getNumberArrayFromSearchParam(newSearchParams.initial_contract__client)
      : defaultSearchParams.filter?.initial_contract__client;
    const advertisement_form = newSearchParams.advertisement_form
      ? getNumberArrayFromSearchParam(newSearchParams.advertisement_form)
      : defaultSearchParams.filter?.advertisement_form;
    const advertisement_type = newSearchParams.advertisement_type
      ? getNumberArrayFromSearchParam(newSearchParams.advertisement_type)
      : defaultSearchParams.filter?.advertisement_type;
    const group = newSearchParams.group
      ? getNumberArrayFromSearchParam(newSearchParams.group)
      : defaultSearchParams.filter?.group;
    setSearchParams({
      page,
      search,
      ordering,
      page_size,
      filter: {
        ord_account,
        combined_status,
        start_date,
        end_date,
        contract,
        marker,
        initial_contract__client,
        advertisement_form,
        advertisement_type,
        group
      }
    });
    dispatch(setCreativeLastSearchParams(searchProperties));
    void creativesRefetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchProperties]);

  useEffect(() => {
    setExportLink(defaultExportLink);
  }, [exportIds]);

  if (creativesError || contractsError) {
    return <div>Error</div>;
  }

  const creatives = creativesInfoList?.data?.results || ([] as NEW_Creative[]);
  const contracts = contractsInfoList?.data?.results || ([] as Contract[]);

  return (
    <ListingLayout
      onFilterToggle={toggleFilter}
      onSearchClick={onSearchClick}
      onPageChange={onPageChange}
      onPageSizeSelect={onPageSizeSelect}
      onOrderSelect={onOrderSelect}
      onResetFilter={onResetFilter}
      onResetPage={() => onPageChange(0)}
      currentSearchQuery={searchParams.search}
      addHref="#/creative/add"
      isDataLoading={creativesLoading || contractsLoading}
      isFilterModalOpen={isFilterOpen}
      currentPageSize={searchParams.page_size}
      currentPage={searchParams.page}
      currentFilter={searchParams.filter}
      currentOrder={searchParams.ordering}
      count={creativesInfoList?.data?.count}
      actionsDropdownMenu={<CreativeListingActions onExport={onExport} exportLink={exportLink} />}
      table={
        <CreativeTable
          creatives={creatives}
          contracts={contracts}
          refetch={creativesRefetch}
          onExportIdCheck={onExportIdCheck}
          setExportIds={setExportIds}
          exportIds={exportIds}
          pageIds={creatives.filter(({ id }) => !!id).map(({ id }) => id as number)}
        />
      }
      orderVariants={orderVariants}
      filter={
        <CreativeFilter
          filter={searchParams.filter}
          clearFilter={defaultSearchParams.filter}
          onFilterClick={onFilterClick}
          onClose={toggleFilter}
        />
      }
      searchPlaceholder="Введите название/ИНН контрагента, номер договора или название группы"
    />
  );
};

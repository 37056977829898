import uniqBy from "lodash/uniqBy";
import { useState } from "react";
import { FaPlus } from "react-icons/fa";
import { useMutation } from "react-query";
import { Button } from "reactstrap";
import FieldLabel from "ui/FieldLabel";
import { PredictiveSearchField } from "ui/PredictiveSearch";
import { searchMutationContract } from "ui/PredictiveSearch/helpers";
import getErrorText from "utils/getErrorText";
import { FieldProps } from "./types";

const InitialContractsField = ({
  control,
  errors,
  contractLoading,
  InitialContractsValues,
  isRequired,
  isShown
}: FieldProps) => {
  const [searchContractValue, setSearchContractValue] = useState<string>();
  const [selected, setSelected] = useState<number[]>([]);

  const {
    mutate: contractSearchRequest,
    isLoading: contractSearchLoading,
    data: contractSearchResponse
  } = useMutation({
    mutationFn: () =>
      Promise.all([
        searchMutationContract(null, null, {
          filter: {
            client__inn: searchContractValue,
            client_role: [1] // Роль заказчика Рекламодатель
          }
        })(),
        searchMutationContract(searchContractValue, null, {
          filter: {
            client_role: [1] // Роль заказчика Рекламодатель
          }
        })(),
        searchMutationContract(null, null, {
          filter: {
            id: selected,
            client_role: [1] // Роль заказчика Рекламодатель
          }
        })()
      ])
        .then(([innRes, searchRes, byIdsRes]) => {
          const results = uniqBy(
            [...innRes.data.results, ...searchRes.data.results, ...byIdsRes.data.results],
            "id"
          );
          return {
            status: true,
            data: { count: results.length, next: "", previous: "", results }
          };
        })
        .catch(() => ({ status: false, data: { count: 0, next: "", previous: "", results: [] } }))
  });

  if (!isShown) return null;

  return (
    <div className="row align-items-center">
      <div className="col-sm-9">
        <PredictiveSearchField
          name="initial_contracts"
          title="Добавьте изначальный договор или несколько если требуется"
          placeholder="Введите номер договора, инн или название контрагента"
          optionKeyLabels={["№ договора", "ИНН рекл-я", "status"]}
          optionKey={["contract_number", "extra_fields.client.inn", "hidden"]}
          inputValue={searchContractValue}
          setInputValue={setSearchContractValue}
          control={control}
          errors={errors}
          errorText={getErrorText(errors, "platform", "Должно быть заполнено.")}
          isLoading={contractSearchLoading}
          request={contractSearchRequest}
          response={contractSearchResponse}
          disabled={contractLoading}
          isMulti
          initialValues={InitialContractsValues as Record<string, string>[]}
          optionDisableCondition={{ status: 2 }}
          infoText="Нельзя выбрать договоры, статус которых &lsquo;ЧЕРНОВИК&rsquo;"
          isRequired={isRequired !== null ? isRequired : false}
          isStared={isRequired !== null}
          onChange={setSelected}
        />
      </div>
      <div className="col-sm-3">
        <FieldLabel title="Добавить контрагента" />
        <Button tag="a" href="#/participant/add" className="w-100 mb-3" target="_blank">
          <FaPlus />
        </Button>
      </div>
    </div>
  );
};

export default InitialContractsField;

import { AxiosError } from "axios";
import find from "lodash/find";
import { INotification } from "models";
import { FieldErrors, FieldValues, UseFormSetError } from "react-hook-form";
import { TAPIResponseError } from "types/ResponseTypes";
import createNotification from "ui/Notification";

export type TNotVisibleFields = { name: string; title: string }[];

const onErrorMutation = (
  err: AxiosError,
  setError?: UseFormSetError<any>,
  notVisibleFields?: TNotVisibleFields
) => {
  const { form_errors, notifications, error_message } = err.response?.data as TAPIResponseError;

  if (form_errors !== null) {
    Object.keys(form_errors).forEach((key) => {
      if ((setError || notVisibleFields) && key !== "non_field_errors") {
        if (setError) setError(key, { type: "custom", message: form_errors[key].join() });
        if (notVisibleFields) {
          const fieldTitle = find(notVisibleFields, (obj) => obj.name === key)?.title;
          createNotification(
            "error",
            `${fieldTitle ? `${fieldTitle}: ` : ""}${form_errors[key].join()}`
          );
        }
      } else {
        createNotification("error", form_errors[key].join());
      }
    });
  }
  if (notifications)
    notifications.forEach((item: INotification) => createNotification(item.tags, item.message));
  if (error_message) createNotification("error", error_message);
};

export const onErrorValidate = (
  err: FieldErrors<FieldValues>,
  notVisibleFieldsCreative: TNotVisibleFields
) => {
  Object.keys(err).forEach((key) => {
    const fieldTitle = find(notVisibleFieldsCreative, (obj) => obj.name === key)?.title;
    fieldTitle && createNotification("error", `Ошибка в поле ${fieldTitle}`);
  });
};

export default onErrorMutation;

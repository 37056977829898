import { FaChevronDown, FaChevronUp } from "react-icons/fa6";
import { Button, Card, CardBody, Collapse } from "reactstrap";
import useIsOpenControl from "utils/useIsOpenControl";
import { TCreativeAttributesElementProps } from "../../types";
import AttributesBody from "./AttributesBody";

const CreativeAttributes = ({
  attributes,
  toggleChangeContractModal,
  toggleChangeGroupModal,
  isContractEditable
}: TCreativeAttributesElementProps) => {
  const { isOpen, toggle } = useIsOpenControl();

  return (
    <>
      <Button
        color="secondary"
        onClick={toggle}
        className="w-100 d-flex justify-content-between align-items-center"
        style={{
          marginBlock: "1rem"
        }}
      >
        <span>Атрибуты креатива</span>
        {isOpen ? <FaChevronUp /> : <FaChevronDown />}
      </Button>
      <Collapse isOpen={isOpen}>
        <Card className="shadow-none">
          <CardBody>
            <AttributesBody
              attributes={attributes}
              toggleChangeContractModal={toggleChangeContractModal}
              toggleChangeGroupModal={toggleChangeGroupModal}
              isContractEditable={isContractEditable}
            />
          </CardBody>
        </Card>
      </Collapse>
    </>
  );
};

export default CreativeAttributes;

import { ErrorMessage } from "Components/ErrorMessage";
import { ID } from "models";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import { StateProps } from "reducers";
import { scrollToError } from "utils/scrollToError";
import { PlatformForm } from "./components/form";
import useEditPlatform from "./hooks/useEditPlatform";
import useLoadPlatform from "./hooks/useLoadPlatform";

type Props = {
  id: ID;
};

const PlatformEditForm = ({ id }: Props) => {
  const platformLastSearch = useSelector((state: StateProps) => state.LastSearchParams.platform);
  const [isDraft, setIsDraft] = useState(false);

  const {
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: { errors },
    setError
  } = useForm();

  const { isLoading, error, searchParticipantsValueDefault } = useLoadPlatform(
    id,
    reset,
    setIsDraft
  );
  const edit = useEditPlatform(id, platformLastSearch, watch, setError);

  const onSubmit = handleSubmit(
    (_, event) => {
      const submitter = (event?.nativeEvent as SubmitEvent).submitter as HTMLButtonElement;
      if (submitter.value) {
        const status = +submitter.value as 1 | 2;
        edit({
          id,
          platform: {
            platform_url: watch("no_url") ? "" : (watch("platform_url") as string),
            platform_type: watch("platform_type") as number,
            platform_name: watch("platform_name") as string,
            is_owner: watch("is_owner") as boolean,
            owner: (watch("owner") as number) ?? undefined,
            status
          }
        });
      }
    },
    (err) => {
      if (err) {
        scrollToError(err);
      }
    }
  );

  if (error) {
    return <ErrorMessage error={error.message} isEmbedded />;
  }

  const buttons = (
    <div className="d-flex gap-3 justify-content-between">
      {isDraft ? (
        <Button
          type="submit"
          color="primary"
          outline
          disabled={isLoading}
          value={2}
          onClick={() => setIsDraft(true)}
        >
          Сохранить черновик
        </Button>
      ) : (
        <div></div>
      )}
      <div className="d-flex gap-3">
        <Button
          href={`#/platform/${platformLastSearch}`}
          tag="a"
          color="primary"
          outline
          disabled={isLoading}
        >
          Отменить
        </Button>
        <Button
          type="submit"
          color="primary"
          disabled={isLoading}
          value={1}
          onClick={() => setIsDraft(false)}
        >
          Сохранить
        </Button>
      </div>
    </div>
  );

  return (
    <div>
      <h1>Редактирование платформы {watch("platform_name")}</h1>

      <PlatformForm
        id={id}
        setValue={setValue}
        control={control}
        watch={watch}
        errors={errors}
        buttons={buttons}
        onSubmit={onSubmit}
        isLoading={isLoading}
        isDraft={isDraft}
        searchParticipantsValueDefault={searchParticipantsValueDefault}
      />
    </div>
  );
};

export default PlatformEditForm;

import { getInvoice } from "api/invoice_item";
import { APIData } from "api/types";
import { ID, Invoice } from "models";
import { Dispatch, SetStateAction } from "react";
import { FieldValues, UseFormReset } from "react-hook-form";
import { useQuery } from "react-query";
import { InitialContractParams } from "../components/form";

const useLoadInvoice = (
  id: ID,
  reset: UseFormReset<FieldValues>,
  setAmountFromAllocationsFile: Dispatch<SetStateAction<number | undefined>>
) => {
  const {
    isLoading,
    error,
    data: invoice
  } = useQuery<APIData<Invoice>, Error>({
    queryKey: ["invoice_item", id],
    queryFn: () => getInvoice(id),
    onSuccess: ({ data, status }) => {
      if (status) {
        reset({ ...data, noNumber: !data.invoice_number });
        const allocated_amount = data?.extra_fields?.allocated_amount;
        setAmountFromAllocationsFile(allocated_amount ? +allocated_amount : undefined);
      }
    }
  });

  return {
    isLoading,
    error,
    invoiceNumber: invoice?.data?.invoice_number,
    initialParams: {
      client: invoice?.data?.extra_fields?.invoice_contract?.client,
      contractor: invoice?.data?.extra_fields?.invoice_contract?.contractor,
      contractNumber: invoice?.data?.extra_fields?.invoice_contract?.number
    } as InitialContractParams
  };
};

export default useLoadInvoice;

import { TParticipantFilter } from "types/TParticipantFilter";
import { TSearchParamsRequired } from "types/TSearchParams";

export const defaultSearchParams: TSearchParamsRequired<TParticipantFilter> = {
  search: "",
  page: 0,
  page_size: 10,
  ordering: "-id",
  filter: {
    legal_form: [],
    ord_account: [],
    combined_status: [],
    role: []
  }
};

export const defaultExportLink = "/api/v1/participant/export/xls";

import {
  Contract,
  ID,
  Invoice,
  NEW_Creative,
  NEW_CreativeGroup,
  Participant,
  Platform
} from "models";
import { ReactNode } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  CardFooter,
  CardHeader,
  CardLink,
  Input,
  ListGroup
} from "reactstrap";
import IExportRelatedProps from "types/IExportRelatedProps";
import { ActionSelectorProps } from "ui/ActionSelector";

interface Props extends IExportRelatedProps {
  id?: ID;
  header?: ReactNode;
  list: ReactNode;
  actions: ActionSelectorProps[];
  item?: NEW_Creative | Invoice | Participant | Contract | Platform | NEW_CreativeGroup;
  onHeaderClick?: (_: ID) => void;
  href?: string;
}

const ListingCard = ({
  id,
  header,
  list,
  actions,
  href,
  onHeaderClick,
  onExportIdCheck,
  exportIds,
  item
}: Props) => (
  <Card className="my-2">
    <CardHeader className="d-flex justify-content-between">
      <CardLink href={href} onClick={() => id && onHeaderClick && onHeaderClick(id)}>
        {header || id}
      </CardLink>
      {id && onExportIdCheck && exportIds ? (
        <Input
          type="checkbox"
          onChange={() => onExportIdCheck(+id)}
          checked={exportIds.includes(+id)}
        />
      ) : null}
    </CardHeader>
    <ListGroup flush>{list}</ListGroup>
    <CardFooter className="border-top justify-content-center">
      <ButtonGroup size="sm">
        {actions.map(({ title, onClick, calcHref, calcIsDisabled }) => {
          const isDisabled = calcIsDisabled && item ? calcIsDisabled(item) : false;
          if (calcHref) {
            const generatedHref = id ? calcHref(id) : "";
            return (
              <a
                href={generatedHref}
                className="btn"
                key={title}
                target="_blank"
                rel="noreferrer"
                download
              >
                {title}
              </a>
            );
          }

          return (
            <Button
              key={title}
              disabled={isDisabled}
              onClick={() => (id && onClick ? onClick(+id, item) : null)}
            >
              {title}
            </Button>
          );
        })}
      </ButtonGroup>
    </CardFooter>
  </Card>
);

export default ListingCard;

import ListingLayout from "Layout/ListingLayout";
import { getPlatformList } from "api/platform_list";
import { APIListData } from "api/types";
import Cookies from "js-cookie";
import { ID, Platform } from "models";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setCloseModalNotNeeded } from "reducers/CloseModalSettings";
import { setPlatformLastSearchParams } from "reducers/LastSearchParams";
import { TPlatformFilter } from "types/TPlatformFilter";
import createNotification from "ui/Notification";
import { OrderingVariant } from "ui/SortSelector";
import getLastPageNumber from "utils/getLastPageNumber";
import getNumberArrayFromSearchParam from "utils/getNumberArrayFromSearchParam";
import getOnlyValidObj from "utils/getOnlyValidObj";
import getSearchParams from "utils/getSearchParams";
import useIsOpenControl from "utils/useIsOpenControl";
import { PlatformFilter } from "./components/filter";
import { PlatformListingActions } from "./components/listingActions";
import { PlatformTable } from "./components/table";
import { defaultExportLink, defaultSearchParams } from "./constants";

const orderVariants: OrderingVariant[] = [
  {
    order: "id",
    title: "ID ↑"
  },
  {
    order: "-id",
    title: "ID ↓"
  },
  {
    order: "platform_name",
    title: "Название ↑"
  },
  {
    order: "-platform_name",
    title: "Название ↓"
  }
];

export const PlatformList = () => {
  const navigate = useNavigate();
  const { pathname, search: searchProperties } = useLocation();
  const dispatch = useDispatch();
  dispatch(setCloseModalNotNeeded());

  const [searchParams, setSearchParams] = useState(defaultSearchParams);

  const { isOpen: isFilterModalOpen, toggle: onFilterToggle } = useIsOpenControl();

  const [exportIds, setExportIds] = useState<ID[]>([]);
  const [exportLink, setExportLink] = useState<string>(defaultExportLink);

  const {
    isLoading: platformsLoading,
    error: platformsError,
    data: platformsInfoList,
    refetch: platformsRefetch
  } = useQuery<APIListData<Platform>, Error>({
    queryKey: [
      "platform_list",
      searchParams.page,
      searchParams.page_size,
      searchParams.search,
      searchParams.filter,
      searchParams.ordering
    ],
    queryFn: () =>
      getPlatformList({
        page: searchParams.page,
        size: searchParams.page_size,
        search: searchParams.search,
        filter: searchParams.filter,
        ordering: searchParams.ordering
      })
  });

  const onPageChange = (page: number) => {
    navigate({
      pathname,
      search: queryString.stringify(
        getOnlyValidObj({
          ...searchParams.filter,
          search: searchParams.search,
          ordering: searchParams.ordering,
          page_size: searchParams.page_size,
          page: page + 1
        })
      )
    });
  };

  const onSearchClick = (query: string) => {
    navigate({
      pathname,
      search: queryString.stringify(
        getOnlyValidObj({
          ...searchParams.filter,
          page_size: searchParams.page_size,
          ordering: searchParams.ordering,
          search: query ? query : undefined,
          page: 1
        })
      )
    });
  };

  const onPageSizeSelect = (page_size: number) => {
    Cookies.set("pageSizePlatform", page_size.toString());
    const lastPageNumber = getLastPageNumber(platformsInfoList?.data?.count || 0, page_size);
    const page = lastPageNumber < searchParams.page ? (lastPageNumber || 1) - 1 : searchParams.page;
    navigate({
      pathname,
      search: queryString.stringify(
        getOnlyValidObj({
          ...searchParams.filter,
          search: searchParams.search,
          ordering: searchParams.ordering,
          page,
          page_size
        })
      )
    });
  };

  const onOrderSelect = (ordering: string) => {
    navigate({
      pathname,
      search: queryString.stringify(
        getOnlyValidObj({
          ...searchParams.filter,
          search: searchParams.search,
          page_size: searchParams.page_size,
          page: 1,
          ordering
        })
      )
    });
  };

  const onFilterClick = (filter: TPlatformFilter) => {
    navigate({
      pathname,
      search: queryString.stringify(
        getOnlyValidObj({
          search: searchParams.search,
          page_size: searchParams.page_size,
          ordering: searchParams.ordering,
          page: 1,
          ...filter
        })
      )
    });
  };

  const onExportIdCheck = (checkedId: ID) => {
    setExportIds((prev) =>
      prev.includes(checkedId) ? prev.filter((item) => item !== checkedId) : [...prev, checkedId]
    );
  };

  const onExport = () => {
    if (exportIds.length) {
      setExportLink(`${defaultExportLink}?${getSearchParams({ filter: { id: exportIds } })}`);
    } else {
      createNotification("warning", "Ничего не выбрано, в файл попадут все данные", 3000);
    }
  };

  const onResetFilter = () => {
    navigate({
      pathname,
      search: queryString.stringify(
        getOnlyValidObj({
          ...defaultSearchParams.filter,
          search: searchParams.search,
          page_size: searchParams.page_size,
          ordering: searchParams.ordering,
          page: 1
        })
      )
    });
  };

  useEffect(() => {
    const cookiePageSize = Cookies.get("pageSizePlatform");
    const newSearchParams = queryString.parse(searchProperties);
    const page = newSearchParams.page ? +newSearchParams.page - 1 : defaultSearchParams.page;
    const search = newSearchParams.search
      ? (newSearchParams.search as string)
      : defaultSearchParams.search;
    const ordering = newSearchParams.ordering
      ? (newSearchParams.ordering as string)
      : defaultSearchParams.ordering;
    const page_size = newSearchParams.page_size
      ? +newSearchParams.page_size
      : cookiePageSize
        ? +cookiePageSize
        : defaultSearchParams.page_size;
    const ord_account = newSearchParams.ord_account
      ? getNumberArrayFromSearchParam(newSearchParams.ord_account)
      : defaultSearchParams.filter?.ord_account;
    const combined_status = newSearchParams.combined_status
      ? getNumberArrayFromSearchParam(newSearchParams.combined_status)
      : defaultSearchParams.filter?.combined_status;
    const platform_type = newSearchParams.platform_type
      ? getNumberArrayFromSearchParam(newSearchParams.platform_type)
      : defaultSearchParams.filter?.platform_type;
    const owner = newSearchParams.owner
      ? getNumberArrayFromSearchParam(newSearchParams.owner)
      : defaultSearchParams.filter?.owner;
    setSearchParams({
      page,
      search,
      ordering,
      page_size,
      filter: {
        ord_account,
        combined_status,
        platform_type,
        owner
      }
    });
    dispatch(setPlatformLastSearchParams(searchProperties));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchProperties]);

  useEffect(() => {
    setExportLink(defaultExportLink);
  }, [exportIds]);

  if (platformsError) {
    return <div>Error</div>;
  }

  const platforms = platformsInfoList?.data?.results || ([] as Platform[]);

  return (
    <ListingLayout
      onFilterToggle={onFilterToggle}
      onSearchClick={onSearchClick}
      onPageChange={onPageChange}
      onPageSizeSelect={onPageSizeSelect}
      onOrderSelect={onOrderSelect}
      onResetFilter={onResetFilter}
      onResetPage={() => onPageChange(0)}
      currentSearchQuery={searchParams.search}
      addHref="#/platform/add"
      isDataLoading={platformsLoading}
      isFilterModalOpen={isFilterModalOpen}
      currentPageSize={searchParams.page_size}
      currentPage={searchParams.page}
      currentFilter={searchParams.filter}
      currentOrder={searchParams.ordering}
      count={platformsInfoList?.data?.count}
      actionsDropdownMenu={
        <PlatformListingActions
          onExport={onExport}
          exportLink={exportLink}
          refetch={platformsRefetch}
        />
      }
      orderVariants={orderVariants}
      table={
        <PlatformTable
          platforms={platforms}
          onExportIdCheck={onExportIdCheck}
          setExportIds={setExportIds}
          exportIds={exportIds}
          pageIds={platforms.filter(({ id }) => !!id).map(({ id }) => id as number)}
          refetch={platformsRefetch}
        />
      }
      filter={
        <PlatformFilter
          filter={searchParams.filter}
          clearFilter={defaultSearchParams.filter}
          onFilterClick={onFilterClick}
          onClose={onFilterToggle}
        />
      }
      searchPlaceholder="Введите URL"
    />
  );
};

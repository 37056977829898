import { deleteContract } from "api/contract_delete";
import contract_status from "dictionaries/contract_status.json";
import contract_type from "dictionaries/contract_type.json";
import { Contract, ID } from "models";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { ListGroupItem } from "reactstrap";
import IExportRelatedProps from "types/IExportRelatedProps";
import { ActionSelectorProps } from "ui/ActionSelector";
import ListingCard from "ui/ListingCard";
import ListingCardsWrapper from "ui/ListingCardsWrapper";
import ListingTable from "ui/ListingTable";
import ListingTableRow from "ui/ListingTableRow";
import ParticipantCell from "ui/ParticipantDisplay/ParticipantCell";
import ParticipantInline from "ui/ParticipantDisplay/ParticipantInline";
import StatusCell from "ui/StatusDisplay/StatusCell";
import StatusInline from "ui/StatusDisplay/StatusInline";
import WrappedCell from "ui/WrappedCell";
import getDate from "utils/getDate";
import getTitleFromDict from "utils/getTitleFromDict";
import onDeleteMutation from "utils/onDeleteMutation";

interface Props extends IExportRelatedProps {
  contracts: Contract[];
  refetch: () => Promise<any>;
}

export const ContractTable = ({
  contracts,
  onExportIdCheck,
  exportIds,
  pageIds,
  setExportIds,
  refetch
}: Props) => {
  const navigate = useNavigate();

  const { mutate: onRowRemove } = useMutation({
    mutationFn: deleteContract,
    onSuccess: (response) =>
      onDeleteMutation({
        response,
        refetch,
        successMessage: "Договор удален"
      })
  });

  const onRowDoubleClick = (id: ID) => navigate(`/contract/${id}/`);

  const actions: ActionSelectorProps[] = [
    {
      title: "Редактировать",
      onClick: onRowDoubleClick
    },
    {
      title: "Удалить",
      onClick: (id) => onRowRemove(id)
    }
  ];

  return (
    <>
      <ListingTable
        theadList={[
          "Тип договора",
          "№ договора",
          "Дата договора",
          "Статус",
          "Заказчик",
          "Исполнитель",
          "Креативы",
          "Акты",
          "Комментарий"
        ]}
        pageIds={pageIds}
        exportIds={exportIds}
        setExportIds={setExportIds}
        tbody={contracts.map((contract: Contract) => (
          <ListingTableRow
            key={contract.id}
            id={contract.id}
            onRowDoubleClick={() => contract.id && onRowDoubleClick(contract.id)}
            onExportIdCheck={onExportIdCheck}
            exportIds={exportIds}
            actions={actions}
            list={
              <>
                <WrappedCell
                  maxLength={20}
                  text={getTitleFromDict(contract.contract_type, contract_type)}
                />
                <td>{contract.contract_number}</td>
                <td>{getDate(contract.contract_date)}</td>
                <StatusCell data={contract} dictionary={contract_status} />
                <ParticipantCell
                  id={`client${contract.id}`}
                  name={contract.extra_fields?.client?.name}
                  ord={contract.extra_fields?.client?.ord_accounts}
                  inn={contract.extra_fields?.client?.inn}
                />
                <ParticipantCell
                  id={`contractor${contract.id}`}
                  name={contract.extra_fields?.contractor?.name}
                  ord={contract.extra_fields?.contractor?.ord_accounts}
                  inn={contract.extra_fields?.contractor?.inn}
                />
                <td>
                  {contract.extra_fields?.creatives_count ? (
                    <a href={`#/creative/?contract=${contract.id}`}>
                      {contract.extra_fields?.creatives_count}
                    </a>
                  ) : (
                    contract.extra_fields?.creatives_count
                  )}
                </td>
                <td>
                  {contract.extra_fields?.invoices_count ? (
                    <a href={`#/invoice/?contract=${contract.id}`}>
                      {contract.extra_fields?.invoices_count}
                    </a>
                  ) : (
                    contract.extra_fields?.invoices_count
                  )}
                </td>
                <WrappedCell maxLength={30} text={contract.comment} />
              </>
            }
          />
        ))}
      />
      <ListingCardsWrapper pageIds={pageIds} exportIds={exportIds} setExportIds={setExportIds}>
        {contracts.map((contract: Contract) => (
          <ListingCard
            key={contract.id}
            id={contract.id}
            href={`#/contract/${contract.id}`}
            onExportIdCheck={onExportIdCheck}
            exportIds={exportIds}
            actions={actions}
            list={
              <>
                <ListGroupItem>
                  Тип договора: {getTitleFromDict(contract.contract_type, contract_type)}
                </ListGroupItem>
                <ListGroupItem>№ договора: {contract.contract_number}</ListGroupItem>
                <ListGroupItem>Дата договора: {getDate(contract.contract_date)}</ListGroupItem>
                <ListGroupItem>
                  Статус: <StatusInline data={contract} dictionary={contract_status} />
                </ListGroupItem>
                <ListGroupItem>
                  Заказчик:{" "}
                  <ParticipantInline
                    id={`client${contract.id}`}
                    name={contract.extra_fields?.client?.name}
                    ord={contract.extra_fields?.client?.ord_accounts}
                    inn={contract.extra_fields?.client?.inn}
                  />
                </ListGroupItem>
                <ListGroupItem>
                  Исполнитель:{" "}
                  <ParticipantInline
                    id={`contractor${contract.id}`}
                    name={contract.extra_fields?.contractor?.name}
                    ord={contract.extra_fields?.contractor?.ord_accounts}
                    inn={contract.extra_fields?.contractor?.inn}
                  />
                </ListGroupItem>
                <ListGroupItem>Комментарий: {contract.comment}</ListGroupItem>
                <ListGroupItem className="d-flex p-0">
                  <div className="py-2 py-2 px-3 w-50">
                    Креативы: <a href="#/creative/">{contract.extra_fields?.creatives_count}</a>
                  </div>
                  <div className="border-start border-1 py-2 px-3">
                    Акты: <a href="#/invoice/">{contract.extra_fields?.invoices_count}</a>
                  </div>
                </ListGroupItem>
              </>
            }
          />
        ))}
      </ListingCardsWrapper>
    </>
  );
};

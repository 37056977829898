import {
  Contract,
  ID,
  Invoice,
  NEW_Creative,
  NEW_CreativeGroup,
  Participant,
  Platform
} from "models";
import { ReactNode } from "react";
import { Input } from "reactstrap";
import { ActionSelector, ActionSelectorProps } from "ui/ActionSelector";

type Props = {
  onRowDoubleClick: () => void;
  onExportIdCheck?: (_: ID) => void;
  exportIds?: ID[];
  id?: ID;
  list: ReactNode;
  actions: ActionSelectorProps[];
  item?: NEW_Creative | Invoice | Participant | Contract | Platform | NEW_CreativeGroup;
};

const ListingTableRow = ({
  onRowDoubleClick,
  onExportIdCheck,
  exportIds,
  id,
  item,
  list,
  actions
}: Props) => (
  <tr onDoubleClick={onRowDoubleClick}>
    {onExportIdCheck && exportIds ? (
      <td>
        <Input
          type="checkbox"
          onChange={() => (id && onExportIdCheck ? onExportIdCheck(+id) : null)}
          checked={id && exportIds ? exportIds.includes(+id) : false}
          disabled={!onExportIdCheck}
        />
      </td>
    ) : null}
    <td scope="row" className="fw-bold">
      {id}
    </td>
    {list}
    <ActionSelector actions={actions} id={id ? +id : undefined} item={item} />
  </tr>
);

export default ListingTableRow;

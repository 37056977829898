import { deleteParticipant } from "api/participant_delete";
import legal_participant_form from "dictionaries/legal_participant_form.json";
import participant_roles from "dictionaries/participant_roles.json";
import participant_status from "dictionaries/participant_status.json";
import { ID, Participant } from "models";
import { Fragment } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { ListGroupItem } from "reactstrap";
import IExportRelatedProps from "types/IExportRelatedProps";
import { TListingItem } from "types/TListingItem";
import { ActionSelectorProps } from "ui/ActionSelector";
import ListingCard from "ui/ListingCard";
import ListingCardsWrapper from "ui/ListingCardsWrapper";
import ListingTable from "ui/ListingTable";
import ListingTableRow from "ui/ListingTableRow";
import ParticipantCell from "ui/ParticipantDisplay/ParticipantCell";
import StatusCell from "ui/StatusDisplay/StatusCell";
import StatusInline from "ui/StatusDisplay/StatusInline";
import getTitleFromDict from "utils/getTitleFromDict";
import onDeleteMutation from "utils/onDeleteMutation";

interface Props extends IExportRelatedProps {
  participants: Participant[];
  refetch: () => Promise<any>;
}

export const ParticipantTable = ({
  participants,
  exportIds,
  onExportIdCheck,
  pageIds,
  setExportIds,
  refetch
}: Props) => {
  const navigate = useNavigate();
  const onRowDoubleClick = (id: ID) => navigate(`/participant/${id}`);
  const participantRoles: {
    [key: string]: string;
  } = participant_roles;

  const { mutate: del } = useMutation({
    mutationFn: deleteParticipant,
    onSuccess: (response) =>
      onDeleteMutation({
        response,
        refetch,
        successMessage: "Контрагент удален"
      })
  });

  const calcIsDisabled = (item: TListingItem) => {
    if (!item) return false;

    const syncStatuses = (item as Participant).extra_fields?.sync_status.map(
      ({ status }) => status
    );

    if (!syncStatuses) return false;

    return syncStatuses.includes("IS_SYNCHRONIZING") || syncStatuses.includes("SYNCHRONIZED");
  };

  const actions: ActionSelectorProps[] = [
    {
      title: "Редактировать контрагента",
      onClick: onRowDoubleClick,
      calcIsDisabled
    },
    {
      title: "Удалить контрагента",
      onClick: (id) => del(id),
      calcIsDisabled
    }
  ];

  return (
    <>
      <ListingTable
        theadList={["Название", "ИНН", "Тип", "Статус", "Роли", "Договоры"]}
        pageIds={pageIds}
        exportIds={exportIds}
        setExportIds={setExportIds}
        tbody={participants.map((participant: Participant) => (
          <ListingTableRow
            key={participant.id}
            id={participant.id}
            item={participant}
            onRowDoubleClick={() => (participant.id ? onRowDoubleClick(participant.id) : null)}
            onExportIdCheck={onExportIdCheck}
            exportIds={exportIds}
            actions={actions}
            list={
              <>
                <ParticipantCell
                  id={`participant${participant.id}`}
                  ord={participant.extra_fields?.ord_accounts}
                  name={participant.name}
                />
                <td>{participant.inn}</td>
                <td>{getTitleFromDict(participant.legal_form, legal_participant_form)}</td>
                <StatusCell data={participant} dictionary={participant_status} />
                <td>
                  {participant.extra_fields?.contracts_roles.map((role) =>
                    role !== null ? (
                      <Fragment key={role}>
                        <span>{participantRoles[role]}</span>
                        <br />
                      </Fragment>
                    ) : null
                  )}
                </td>
                <td>
                  {participant.extra_fields?.contracts_count ? (
                    <a href={`#contract/?participant=${participant.id}`}>
                      {participant.extra_fields?.contracts_count}
                    </a>
                  ) : (
                    participant.extra_fields?.contracts_count
                  )}
                </td>
              </>
            }
          />
        ))}
      />
      <ListingCardsWrapper pageIds={pageIds} exportIds={exportIds} setExportIds={setExportIds}>
        {participants.map((participant: Participant) => (
          <ListingCard
            key={participant.id}
            id={participant.id}
            onHeaderClick={() => participant.id && onRowDoubleClick(participant.id)}
            header={`${participant.id} | ${participant.name}`}
            actions={actions}
            item={participant}
            href={`#/participant/${participant.id}`}
            onExportIdCheck={onExportIdCheck}
            exportIds={exportIds}
            list={
              <>
                <ListGroupItem>ИНН: {participant.inn}</ListGroupItem>
                <ListGroupItem>
                  Тип: {getTitleFromDict(participant.legal_form, legal_participant_form)}
                </ListGroupItem>
                <ListGroupItem>
                  Статус: <StatusInline data={participant} dictionary={participant_status} />
                </ListGroupItem>
                <ListGroupItem>
                  Роли:{" "}
                  {participant.extra_fields?.contracts_roles
                    .map((role) => participantRoles[role])
                    .join(", ")}
                </ListGroupItem>
                <ListGroupItem>
                  Договоры:{" "}
                  <a href={`#contract/?participant=${participant.id}`}>
                    {participant.extra_fields?.contracts_count}
                  </a>
                </ListGroupItem>
              </>
            }
          />
        ))}
      </ListingCardsWrapper>
    </>
  );
};

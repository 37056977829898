import { ID, Invoice } from "models";
import { APIData } from "./types";

export const getInvoice = (id: ID): Promise<APIData<Invoice>> => {
  return fetch(`/api/v1/invoice/${id}/`, {
    mode: "cors",
    headers: {
      "X-Requested-With": "XMLHttpRequest"
    }
  })
    .then((res) => {
      const result = res.json() as Promise<APIData<Invoice>>;
      return result;
    })
    .then((res) => {
      if (res.status === false) {
        throw new Error(res.error_message);
      }
      return res;
    });
};

import { deleteParticipant } from "api/participant_delete";
import { FieldValues, UseFormWatch } from "react-hook-form";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import onDeleteMutation from "utils/onDeleteMutation";

const useDeleteParticipant = (participantLastSearch: string, watch: UseFormWatch<FieldValues>) => {
  const navigate = useNavigate();

  const { mutate } = useMutation({
    mutationFn: deleteParticipant,
    onSuccess: (response) =>
      onDeleteMutation({
        response,
        href: `/participant/${participantLastSearch}`,
        navigate,
        successMessage: `Контрагент ${watch("name")} удален`
      })
  });

  return mutate;
};

export default useDeleteParticipant;

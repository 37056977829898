import { ID } from "models";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import { scrollToError } from "utils/scrollToError";
import { StatisticsForm } from "./components/form";

type Props = {
  id: ID;
};

const StatisticsEditForm = ({ id }: Props) => {
  const [amountFromAllocationsFile, setAmountFromAllocationsFile] = useState<number>();
  const [isDraft, setIsDraft] = useState(false);

  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setValue
  } = useForm();

  // const { mutate: edit } = useMutation({
  //   mutationFn: editStatistics,
  //   onSuccess: (p) => {},
  //   onError: (err) => onErrorMutation(err as AxiosError, setError)
  // });

  const onSubmit = handleSubmit(
    (_, event) => {
      // TODO: добавить запрос редактирования
    },
    (err) => {
      if (err) {
        scrollToError(err);
      }
    }
  );

  const buttons = (
    <div className="d-flex gap-3 justify-content-between">
      <Button type="submit" color="primary" value={6} onClick={() => setIsDraft(true)}>
        Сохранить черновик
      </Button>
      <div className="d-flex gap-3">
        <Button href="#/invoice" tag="a" color="primary" outline>
          Отменить
        </Button>
        <Button
          type="submit"
          color="danger"
          value={1}
          disabled={!watch("allocations_file")}
          onClick={() => setIsDraft(false)}
        >
          Сохранить
        </Button>
      </div>
    </div>
  );

  return (
    <div>
      <h1>Редактирование статистики без акта</h1>
      <StatisticsForm
        control={control}
        errors={errors}
        onSubmit={onSubmit}
        buttons={buttons}
        watch={watch}
        setAmountFromAllocationsFile={setAmountFromAllocationsFile}
        isDraft={isDraft}
        setValue={setValue}
      />
    </div>
  );
};

export default StatisticsEditForm;

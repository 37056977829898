import { useEffect, useState } from "react";
import { Progress } from "reactstrap";

const ProgressLoader = ({ duration = 2000 }: { duration?: number }) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    const timerId = setInterval(() => {
      setValue((prev) => prev + 20);
    }, duration / 5);

    setTimeout(() => {
      clearInterval(timerId);
      setValue(99);
    }, duration);
  }, []);

  return (
    <Progress
      color="secondary"
      value={value}
      style={{ height: "5px", marginBottom: "5px", borderRadius: 0 }}
    />
  );
};

export default ProgressLoader;

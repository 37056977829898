import { ErrorMessage } from "Components/ErrorMessage";
import { format } from "date-fns";
import omit from "lodash/omit";
import { Contract, ID } from "models";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import { StateProps } from "reducers";
import { scrollToError } from "utils/scrollToError";
import { ContractForm } from "./components/form";
import useDeleteContract from "./hooks/useDeleteContract";
import useEditContract from "./hooks/useEditContract";
import useLoadContract from "./hooks/useLoadContract";
import useLoadInitialContracts from "./hooks/useLoadInitialContracts";

type Props = {
  id: ID;
};

const ContractEditForm = ({ id }: Props) => {
  const contractLastSearch = useSelector((state: StateProps) => state.LastSearchParams.contract);
  const [isDraft, setIsDraft] = useState(false);

  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
    setError,
    setValue,
    resetField
  } = useForm();

  const {
    isLoading,
    error,
    initialContractsIds,
    searchClientParticipantsValueDefault,
    searchContractorParticipantsValueDefault,
    status
  } = useLoadContract(id, reset);
  const { initialContractsList } = useLoadInitialContracts(initialContractsIds);
  const edit = useEditContract(id, contractLastSearch, watch, setError);
  const del = useDeleteContract(id, contractLastSearch, watch);

  const onSubmit = handleSubmit(
    (_, event) => {
      const submitter = (event?.nativeEvent as SubmitEvent).submitter as HTMLButtonElement;
      if (submitter.value) {
        const status = submitter.value as 1 | 2 | "delete";

        const {
          client,
          contractor,
          subject_type,
          contract_number,
          contract_date,
          creatives_registrar
        } =
          watch("contract_type") == 4
            ? {
                client: watch("self_ad_participant") as Contract["client"],
                contractor: watch("self_ad_participant") as Contract["contractor"],
                subject_type: 1 as Contract["subject_type"], // 'Иное'
                contract_number: "" as Contract["contract_number"],
                contract_date: format(new Date(), "yyyy-MM-dd") as Contract["contract_date"],
                creatives_registrar: 2 as Contract["creatives_registrar"] // 'Исполнитель'
              }
            : {
                client: watch("client") as Contract["client"],
                contractor: watch("contractor") as Contract["contractor"],
                subject_type: watch("subject_type") as Contract["subject_type"],
                contract_number: (watch("contract_number") || "") as Contract["contract_number"],
                contract_date: watch("contract_date") as Contract["contract_date"],
                creatives_registrar: watch("creatives_registrar") as Contract["creatives_registrar"]
              };

        const is_agent_acting_for_publisher =
          watch("is_agent_acting_for_publisher") !== undefined
            ? !!watch("is_agent_acting_for_publisher")
            : undefined;

        if (status === "delete") {
          del(id);
        } else {
          edit({
            id,
            contract: {
              ...omit(watch(), [
                "noNumber",
                "self_ad_participant",
                "id",
                "contractor_ord",
                "client_ord"
              ]),
              vat_included: !!(watch("vat_included") as 0 | 1 | undefined),
              is_agent_acting_for_publisher,
              client,
              contractor,
              subject_type,
              contract_number,
              contract_date,
              creatives_registrar,
              status
            }
          });
        }
      }
    },
    (err) => {
      if (err) {
        scrollToError(err);
      }
    }
  );

  const buttons = {
    draft: (
      <div className="d-flex gap-3 justify-content-between">
        <div className="d-flex gap-3">
          <Button
            type="submit"
            color="danger"
            disabled={isLoading}
            value="delete"
            onClick={() => setIsDraft(true)}
          >
            Удалить
          </Button>
          <Button
            type="submit"
            color="primary"
            disabled={isLoading}
            value={2}
            onClick={() => setIsDraft(true)}
          >
            Сохранить
          </Button>
        </div>
        <div className="d-flex gap-3">
          <Button
            href={`#/contract/${contractLastSearch}`}
            tag="a"
            color="primary"
            outline
            disabled={isLoading}
          >
            Отменить
          </Button>
          <Button
            type="submit"
            color="primary"
            disabled={isLoading}
            value={1}
            onClick={() => setIsDraft(false)}
          >
            Добавить
          </Button>
        </div>
      </div>
    ),
    active: (
      <div className="d-flex gap-3 justify-content-between">
        <div className="d-flex gap-3">
          {/* <Button type="submit" color="danger" disabled={isLoading} value={3}>
            Архивировать
          </Button> */}
        </div>
        <div className="d-flex gap-3">
          <Button
            href={`#/contract/${contractLastSearch}`}
            tag="a"
            color="primary"
            outline
            disabled={isLoading}
          >
            Отменить
          </Button>
          <Button type="submit" color="primary" disabled={isLoading} value={1}>
            Сохранить
          </Button>
        </div>
      </div>
    )
  };

  if (error) {
    return <ErrorMessage error={error.message} isEmbedded />;
  }

  return (
    <div>
      <h1>Редактирование договора {watch("id")}</h1>
      <ContractForm
        control={control}
        errors={errors}
        onSubmit={onSubmit}
        buttons={buttons[status]}
        watch={watch}
        setValue={setValue}
        resetField={resetField}
        contractLoading={isLoading}
        isDraft={isDraft}
        InitialContractsValues={initialContractsList}
      />
    </div>
  );
};

export default ContractEditForm;

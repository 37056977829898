import { useEffect, useState } from "react";
import { FieldValues, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { IoCheckmark, IoClose } from "react-icons/io5";
import { Button, Input, InputGroup } from "reactstrap";
import getTextShortened from "utils/getTextShortened";
import useIsOpenControl from "utils/useIsOpenControl";

type Props = {
  watch?: UseFormWatch<FieldValues>;
  setValue?: UseFormSetValue<FieldValues>;
  name: string;
  extraAction?: () => void;
};

const DescriptionField = ({ name, watch, setValue, extraAction }: Props) => {
  const {
    isOpen: isEditing,
    setOpen: setIsEditing,
    setClose: setIsNotEditing
  } = useIsOpenControl();

  const [newState, setNewState] = useState<string | undefined>();

  useEffect(() => {
    if (isEditing && watch) {
      setNewState(watch(name) as string | undefined);
    }
  }, [isEditing, watch, name]);

  const onClose = () => {
    setIsNotEditing();
  };

  const onSubmit = () => {
    setValue && setValue(name, newState);
    extraAction && extraAction();
    setIsNotEditing();
  };

  if (!name || !watch) return null;

  if (isEditing)
    return (
      <InputGroup>
        <Input
          value={newState || ""}
          onChange={(e) => {
            setNewState(e.target.value);
          }}
          type="text"
          onKeyDown={(e) => {
            if (e.key === "Escape") {
              onClose();
            }

            if (e.key === "Enter") {
              onSubmit();
            }
          }}
        />
        <Button onClick={onClose} color="danger">
          <IoClose size={20} />
        </Button>
        <Button onClick={onSubmit} color="success">
          <IoCheckmark size={20} />
        </Button>
      </InputGroup>
    );

  const str = watch(name) as string | undefined;

  return (
    <p onDoubleClick={setIsEditing} className="mb-0">
      {str ? getTextShortened(str, 50) : <i>Нет описания</i>}
    </p>
  );
};

export default DescriptionField;

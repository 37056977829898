import { ParticipantFormDV } from "constants/defaultValues/ParticipantFormDV";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import { StateProps } from "reducers";
import { scrollToError } from "utils/scrollToError";
import { ParticipantForm } from "./components/form";
import useAddParticipant from "./hooks/useAddParticipant";

const ParticipantAddForm = () => {
  const participantLastSearch = useSelector(
    (state: StateProps) => state.LastSearchParams.participant
  );
  const [isDraft, setIsDraft] = useState(false);

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setError
  } = useForm({ defaultValues: ParticipantFormDV });

  const add = useAddParticipant(participantLastSearch, watch, setError);

  const onSubmit = handleSubmit(
    (_, event) => {
      const submitter = (event?.nativeEvent as SubmitEvent).submitter as HTMLButtonElement;
      if (submitter.value) {
        const status = +submitter.value as 1 | 2 | 3;
        add({
          participant: {
            ...watch(),
            status
          }
        });
      }
    },
    (err) => {
      if (err) {
        scrollToError(err);
      }
    }
  );

  const buttons = (
    <div className="d-flex gap-3 justify-content-between">
      <Button type="submit" color="primary" outline value={2} onClick={() => setIsDraft(true)}>
        Сохранить черновик
      </Button>
      <div className="d-flex gap-3">
        <Button href={`#/participant/${participantLastSearch}`} tag="a" color="primary" outline>
          Отменить
        </Button>
        <Button type="submit" color="primary" value={1} onClick={() => setIsDraft(false)}>
          Добавить
        </Button>
      </div>
    </div>
  );

  return (
    <div>
      <h1>Добавление контрагента</h1>
      <ParticipantForm
        control={control}
        errors={errors}
        onSubmit={onSubmit}
        buttons={buttons}
        watch={watch}
        isDraft={isDraft}
      />
    </div>
  );
};

export default ParticipantAddForm;

import difference from "lodash/difference";
import { ReactNode } from "react";
import { Input, Table } from "reactstrap";
import IExportRelatedProps from "types/IExportRelatedProps";

interface Props extends IExportRelatedProps {
  theadList: string[];
  tbody: ReactNode;
}

const ListingTable = ({ theadList, tbody, pageIds, exportIds, setExportIds }: Props) => {
  return (
    <Table hover className="mb-0 d-none d-sm-block" responsive>
      <thead>
        <tr>
          {setExportIds && exportIds && pageIds ? (
            <th>
              <Input
                type="checkbox"
                onChange={() => {
                  difference(pageIds, exportIds).length !== 0
                    ? setExportIds((prev) => Array.from(new Set([...pageIds, ...prev])))
                    : setExportIds((prev) => prev.filter((item) => !pageIds.includes(item)));
                }}
                disabled={!(!!setExportIds && pageIds && exportIds)}
                checked={pageIds && exportIds && difference(pageIds, exportIds).length === 0}
              />
            </th>
          ) : null}
          <th>ID</th>
          {theadList.map((header) => (
            <th key={header} dangerouslySetInnerHTML={{ __html: header }} />
          ))}
          <th>Действие</th>
        </tr>
      </thead>
      <tbody>{tbody}</tbody>
    </Table>
  );
};

export default ListingTable;

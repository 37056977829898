import { Fragment, Suspense } from "react";

import { Navigate, Route, Routes } from "react-router-dom";

import { ErrorMessage } from "Components/ErrorMessage";
import { LoadingMessage } from "Components/LoadingMessage";
import DefaultLayout from "Layout/DefaultLayout";
import ContractFormPage from "Pages/ContractFormPage";
import ContractPage from "Pages/ContractPage";
import CreativeFormPage from "Pages/CreativeFormPage";
import CreativeGroupFormPage from "Pages/CreativeGroupFormPage";
import CreativePage from "Pages/CreativePage";
import DashboardPage from "Pages/DashboardPage";
import HelpPage from "Pages/HelpPage";
import InvoiceFormPage from "Pages/InvoiceFormPage";
import InvoicePage from "Pages/InvoicePage";
import LoginBoxed from "Pages/LoginBoxed";
import ParticipantFormPage from "Pages/ParticipantFormPage";
import ParticipantPage from "Pages/ParticipantPage";
import PlatformFormPage from "Pages/PlatformFormPage";
import PlatformPage from "Pages/PlatformPage";
import StatisticsFormPage from "Pages/StatisticsFormPage";
import StatisticsPage from "Pages/StatisticsPage";
import { useUserInfo } from "api/user_info";
import { ToastContainer } from "react-toastify";
import CloseModal from "ui/CloseModal";

const AppMain = () => {
  const user_info = useUserInfo();

  if (user_info.isLoading) {
    return <LoadingMessage />;
  }

  if (user_info.error) {
    return <ErrorMessage />;
  }

  if (user_info.data && !user_info.data.data.user.id) {
    return <LoginBoxed />;
  }

  return (
    <Fragment>
      <Suspense fallback={<LoadingMessage />}>
        <Routes>
          <Route path="/" element={<Navigate replace to="/participant" />} />
          <Route Component={DefaultLayout}>
            <Route path="/help" Component={HelpPage} />
            <Route path="/participant" Component={ParticipantPage} />
            <Route path="/participant/:id" Component={ParticipantFormPage} />
            <Route path="/contract" Component={ContractPage} />
            <Route path="/contract/:id" Component={ContractFormPage} />
            <Route path="/platform" Component={PlatformPage} />
            <Route path="/platform/:id" Component={PlatformFormPage} />
            <Route path="/invoice" Component={InvoicePage} />
            <Route path="/invoice/:id" Component={InvoiceFormPage} />
            <Route path="/statistics" Component={StatisticsPage} />
            <Route path="/statistics/:id" Component={StatisticsFormPage} />
            <Route path="/creative" Component={CreativePage} />
            <Route path="/creative/:id" Component={CreativeFormPage} />
            <Route path="/creative/group/:id" Component={CreativeGroupFormPage} />
            <Route path="/dashboard" Component={DashboardPage} />
            <Route path="/dashboard/:tab" Component={DashboardPage} />
            {/* <Route path="/welcome" Component={WelcomePage} />
            <Route path="/personal" Component={PersonalPage} />
            <Route path="*" Component={ErrorPage} /> */}
          </Route>
        </Routes>
      </Suspense>
      <ToastContainer />
      <CloseModal />
    </Fragment>
  );
};

export default AppMain;

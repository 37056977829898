import { getParticipantList } from "api/participant_list";
import ParticipantContractorRolesOptions from "constants/selectOptions/ParticipantContractorRolesOptions";
import { useState } from "react";
import { useMutation } from "react-query";
import { PredictiveSearchField } from "ui/PredictiveSearch";
import { SelectField } from "ui/Select";
import getErrorText from "utils/getErrorText";
import { FieldProps } from "./types";

const ContractorField = ({
  control,
  errors,
  contractLoading,
  watch,
  setValue,
  isShown,
  isRequired
}: FieldProps) => {
  const [searchContractorParticipantsValue, setSearchContractorParticipantsValue] = useState<
    string | undefined
  >(undefined);

  const {
    mutate: contractorParticipantsRequest,
    isLoading: contractorParticipantsLoading,
    data: contractorParticipantsResponse
  } = useMutation({
    mutationFn: () => {
      const contractorId = watch("contractor") as number | undefined;
      return getParticipantList(
        searchContractorParticipantsValue
          ? { search: searchContractorParticipantsValue }
          : { filter: contractorId ? { id: [contractorId] } : {} }
      );
    }
  });

  const validate = () => {
    const { contract_type, contractor, client } = watch();
    if (contractor && client && contractor === client && contract_type !== 4) {
      return 'Выберите другого контаргента или выберите тип договора "Самореклама"';
    }
    if (contractor && client && contractor !== client && contract_type === 4) {
      return 'Заказчик и Исполнитель должны быть одинаковыми при типе договора "Самореклама"';
    }
  };

  if (!isShown) return null;

  return (
    <div className="row align-items-center">
      <div className="col-sm-9">
        <PredictiveSearchField
          name="contractor"
          title="Исполнитель"
          optionKey={["name", "inn", "status"]}
          optionKeyLabels={["Название", "ИНН", "hidden"]}
          inputValue={searchContractorParticipantsValue}
          setInputValue={setSearchContractorParticipantsValue}
          control={control}
          errors={errors}
          errorText={getErrorText(errors, "contractor", "Должно быть заполнено.")}
          isLoading={contractorParticipantsLoading}
          request={contractorParticipantsRequest}
          response={contractorParticipantsResponse}
          disabled={contractLoading}
          optionDisableCondition={{ status: 2 }}
          infoText="Нельзя выбрать контрагентов, статус которых &lsquo;ЧЕРНОВИК&rsquo;"
          validate={validate}
          onChange={(value) => {
            if (watch("contract_type") === 4) setValue("client", value);

            const contractor = contractorParticipantsResponse?.data.results.find(
              (p) => p.id === value
            );

            if (contractor) setValue("contractor_ord", contractor.extra_fields?.ord_accounts);
          }}
          isRequired={isRequired !== null ? isRequired : false}
          isStared={isRequired !== null}
        />
      </div>
      <div className="col-sm-3">
        <SelectField
          name="contractor_role"
          title="Выберите роль"
          control={control}
          errors={errors}
          disabled={contractorParticipantsLoading || contractLoading}
          options={ParticipantContractorRolesOptions}
          onChange={(value) => {
            // "Оператор рекламной системы" или "Рекламораспространитель"
            if (value === 3 || value === 4) {
              setValue("creatives_registrar", 2);
            } else {
              // "Рекламное агентство"
              setValue("creatives_registrar", 1);
            }
          }}
          isRequired={isRequired !== null ? isRequired : false}
          isStared={isRequired !== null}
        />
      </div>
    </div>
  );
};

export default ContractorField;

import { getContractList } from "api/contract_list";
import { getParticipantList } from "api/participant_list";
import { uploadAllocations } from "api/upload_file";
import { ID } from "models";
import {
  BaseSyntheticEvent,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState
} from "react";
import { useForm } from "react-hook-form";
import {
  Control,
  FieldErrors,
  FieldValues,
  UseFormSetValue,
  UseFormWatch
} from "react-hook-form/dist/types";
import { useMutation } from "react-query";
import { Form } from "reactstrap";
import { DatePickerField, DropZoneField, PredictiveSearchField } from "ui";

type Props = {
  control: Control<FieldValues, any>;
  errors: FieldErrors<FieldValues>;
  onSubmit: (_e?: BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  buttons: ReactNode;
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  isLoading?: boolean;
  initialParams?: {
    client: {
      id: ID;
      name: string;
    };
    contractor: {
      id: ID;
      name: string;
    };
    contractNumber: string;
  };
  setAmountFromAllocationsFile: Dispatch<SetStateAction<any>>;
  isDraft: boolean;
};

export const StatisticsForm = ({
  control,
  errors,
  onSubmit,
  buttons,
  watch,
  isLoading,
  initialParams,
  setAmountFromAllocationsFile,
  isDraft,
  setValue
}: Props) => {
  const [searchClientParticipantsValue, setSearchClientParticipantsValue] = useState<
    string | undefined
  >(initialParams ? initialParams.client.name : undefined);
  const [searchContractorParticipantsValue, setSearchContractorParticipantsValue] = useState<
    string | undefined
  >(initialParams ? initialParams.contractor.name : undefined);
  const [searchContractsValue, setSearchContractsValue] = useState<string | undefined>(
    initialParams ? initialParams.contractNumber : undefined
  );

  const { control: contractControl, reset: contractReset, watch: contractWatch } = useForm();

  const {
    mutate: clientParticipantsRequest,
    isLoading: clientParticipantsLoading,
    data: clientParticipantsResponse
  } = useMutation({
    mutationFn: () =>
      getParticipantList({
        search: searchClientParticipantsValue ?? ""
      })
  });

  const {
    mutate: contractorParticipantsRequest,
    isLoading: contractorParticipantsLoading,
    data: contractorParticipantsResponse
  } = useMutation({
    mutationFn: () =>
      getParticipantList({
        search: searchContractorParticipantsValue ?? ""
      })
  });

  const {
    mutate: contractsRequest,
    isLoading: contractsLoading,
    data: contractsResponse
  } = useMutation({
    mutationFn: () =>
      getContractList({
        search: searchContractsValue,
        filter: {
          client: contractWatch("client") as string,
          contractor: contractWatch("contractor") as string
        }
      })
  });

  useEffect(() => {
    clientParticipantsRequest();
    contractorParticipantsRequest();
    if (initialParams) {
      contractReset({
        client: initialParams?.client.id,
        contractor: initialParams?.contractor.id
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (contractWatch("client") && contractWatch("contractor")) {
      contractsRequest();
    } else {
      setValue("contract", undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractWatch("client"), contractWatch("contractor")]);

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  return (
    <Form
      onSubmit={(event) => {
        void onSubmit(event);
      }}
      className="mt-3 mb-3"
    >
      <div className="row align-items-center">
        <div className="col-sm-9">
          <PredictiveSearchField
            name="client"
            title="Заказчик"
            optionKeyLabels={["Название", "ИНН", "hidden"]}
            optionKey={["name", "inn", "status"]}
            inputValue={searchClientParticipantsValue}
            setInputValue={setSearchClientParticipantsValue}
            control={contractControl}
            isLoading={clientParticipantsLoading}
            request={clientParticipantsRequest}
            response={clientParticipantsResponse}
            disabled={isLoading}
            clearable
            optionDisableCondition={{ status: 2 }}
            infoText="Нельзя выбрать контрагентов, статус которых &lsquo;ЧЕРНОВИК&rsquo;"
          />
        </div>
      </div>
      <div className="row align-items-center">
        <div className="col-sm-9">
          <PredictiveSearchField
            name="contractor"
            title="Исполнитель"
            optionKeyLabels={["Название", "ИНН", "hidden"]}
            optionKey={["name", "inn", "status"]}
            inputValue={searchContractorParticipantsValue}
            setInputValue={setSearchContractorParticipantsValue}
            control={contractControl}
            isLoading={contractorParticipantsLoading}
            request={contractorParticipantsRequest}
            response={contractorParticipantsResponse}
            disabled={isLoading}
            clearable
            optionDisableCondition={{ status: 2 }}
            infoText="Нельзя выбрать контрагентов, статус которых &lsquo;ЧЕРНОВИК&rsquo;"
          />
        </div>
      </div>
      <div className="row align-items-center">
        <div className="col-sm-9">
          <PredictiveSearchField
            name="contract"
            title="Выберите договор к которому относится акт"
            optionKey={["contract_number", "client", "contractor", "status"]}
            optionKeyLabels={["Номер контракта", "client", "contractor", "hidden"]}
            inputValue={searchContractsValue}
            setInputValue={setSearchContractsValue}
            control={control}
            isLoading={contractsLoading}
            request={contractsRequest}
            response={contractsResponse}
            disabled={isLoading || !contractWatch("client") || !contractWatch("contractor")}
            clearable
            errorText="Необходимо заполнить. Для заполнения выберете заказчика и исполнителя."
            errors={errors}
            isRequired
            optionDisableCondition={{ status: 2 }}
            infoText="Нельзя выбрать договоры, статус которых &lsquo;ЧЕРНОВИК&rsquo;"
          />
        </div>
      </div>
      <div className="row align-items-center">
        <div className="col-sm-6">
          <DatePickerField
            name="start_date"
            title="Начало периода загрузки статистики"
            control={control}
            errors={errors}
            isRequired={!isDraft}
            errorText="Необходимо заполнить."
            disabled={isLoading}
            returnFormat="yyyy-MM-dd"
          />
        </div>
        <div className="col-sm-6">
          <DatePickerField
            name="end_date"
            title="Окончание периода загрузки статистики"
            control={control}
            errors={errors}
            isRequired={!isDraft}
            errorText="Необходимо заполнить."
            disabled={isLoading}
            returnFormat="yyyy-MM-dd"
          />
        </div>
      </div>
      <DropZoneField
        name="allocations_file"
        title="Файл с данными по статистике без актов"
        exampleLink="/static/core/invoice_templates/allocation.xlsx"
        control={control}
        errors={errors}
        fileTypes={[".xlsx", ".xls", ".csv"]}
        uploadFunction={uploadAllocations}
        isRequired={!isDraft}
        responsePrintField="file_path"
        setAdditionalData={{
          fn: setAmountFromAllocationsFile,
          field: "amount"
        }}
      />
      {buttons}
    </Form>
  );
};

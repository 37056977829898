export const getYears = () => {
  const year = new Date().getFullYear() - 15;
  return Array.from({ length: 30 }, (v, i) => year + i).map((val) => ({ value: val, label: val }));
};

export const months = [
  { value: 0, label: "Январь" },
  { value: 1, label: "Февраль" },
  { value: 2, label: "Март" },
  { value: 3, label: "Апрель" },
  { value: 4, label: "Май" },
  { value: 5, label: "Июнь" },
  { value: 6, label: "Июль" },
  { value: 7, label: "Август" },
  { value: 8, label: "Сентябрь" },
  { value: 9, label: "Октябрь" },
  { value: 10, label: "Ноябрь" },
  { value: 11, label: "Декабрь" }
];

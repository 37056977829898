import ListingLayout from "Layout/ListingLayout";
import { getContractList } from "api/contract_list";
import Cookies from "js-cookie";
import { Contract, ID } from "models";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setCloseModalNotNeeded } from "reducers/CloseModalSettings";
import { setContractLastSearchParams } from "reducers/LastSearchParams";
import { TContractFilter } from "types/TContractFilter";
import createNotification from "ui/Notification";
import { OrderingVariant } from "ui/SortSelector";
import getLastPageNumber from "utils/getLastPageNumber";
import getNumberArrayFromSearchParam from "utils/getNumberArrayFromSearchParam";
import getOnlyValidObj from "utils/getOnlyValidObj";
import getSearchParams from "utils/getSearchParams";
import getStringFromSearchParam from "utils/getStringFromSearchParam";
import useIsOpenControl from "utils/useIsOpenControl";
import { ContractFilter } from "./components/filter";
import { ContractListingActions } from "./components/listingActions";
import { ContractTable } from "./components/table";
import { defaultExportLink, defaultSearchParams } from "./constants";

const orderVariants: OrderingVariant[] = [
  {
    order: "id",
    title: "ID ↑"
  },
  {
    order: "-id",
    title: "ID ↓"
  },
  {
    order: "contract_number",
    title: "№ договора ↑"
  },
  {
    order: "-contract_number",
    title: "№ договора ↓"
  },
  {
    order: "contract_date",
    title: "Дата договора ↑"
  },
  {
    order: "-contract_date",
    title: "Дата договора ↓"
  }
];

export const ContractList = () => {
  const navigate = useNavigate();
  const { pathname, search: searchProperties } = useLocation();
  const dispatch = useDispatch();
  dispatch(setCloseModalNotNeeded());

  const [searchParams, setSearchParams] = useState(defaultSearchParams);

  const { isOpen: isFilterModalOpen, toggle: onFilterToggle } = useIsOpenControl();

  const [exportIds, setExportIds] = useState<ID[]>([]);
  const [exportLink, setExportLink] = useState<string>(defaultExportLink);

  const {
    isLoading: contractsLoading,
    error: contractsError,
    data: contractsInfoList,
    refetch: contractsRefetch
  } = useQuery({
    queryKey: [
      "contract_list",
      searchParams.page,
      searchParams.page_size,
      searchParams.search,
      searchParams.filter,
      searchParams.ordering
    ],
    queryFn: () =>
      getContractList({
        page: searchParams.page,
        size: searchParams.page_size,
        search: searchParams.search,
        filter: searchParams.filter,
        ordering: searchParams.ordering
      })
  });

  const onPageChange = (page: number) => {
    navigate({
      pathname,
      search: queryString.stringify(
        getOnlyValidObj({
          ...searchParams.filter,
          search: searchParams.search,
          page_size: searchParams.page_size,
          ordering: searchParams.ordering,
          page: page + 1
        })
      )
    });
  };

  const onSearchClick = (query: string) => {
    navigate({
      pathname,
      search: queryString.stringify(
        getOnlyValidObj({
          ...searchParams.filter,
          page_size: searchParams.page_size,
          ordering: searchParams.ordering,
          search: query ? query : undefined,
          page: 1
        })
      )
    });
  };

  const onPageSizeSelect = (page_size: number) => {
    Cookies.set("pageSizeContact", page_size.toString());
    const lastPageNumber = getLastPageNumber(contractsInfoList?.data?.count || 0, page_size);
    const page = lastPageNumber < searchParams.page ? (lastPageNumber || 1) - 1 : searchParams.page;
    navigate({
      pathname,
      search: queryString.stringify(
        getOnlyValidObj({
          ...searchParams.filter,
          search: searchParams.search,
          ordering: searchParams.ordering,
          page,
          page_size
        })
      )
    });
  };

  const onFilterClick = (filter: TContractFilter) => {
    navigate({
      pathname,
      search: queryString.stringify(
        getOnlyValidObj({
          search: searchParams.search,
          page_size: searchParams.page_size,
          ordering: searchParams.ordering,
          page: 1,
          ...filter
        })
      )
    });
  };

  const onExportIdCheck = (checkedId: ID) => {
    setExportIds((prev) =>
      prev.includes(checkedId) ? prev.filter((item) => item !== checkedId) : [...prev, checkedId]
    );
  };

  const onExport = () => {
    if (exportIds.length) {
      setExportLink(`${defaultExportLink}?${getSearchParams({ filter: { id: exportIds } })}`);
    } else {
      createNotification("warning", "Ничего не выбрано, в файл попадут все данные", 3000);
    }
  };
  const onOrderSelect = (ordering: string) => {
    navigate({
      pathname,
      search: queryString.stringify(
        getOnlyValidObj({
          ...searchParams.filter,
          search: searchParams.search,
          page_size: searchParams.page_size,
          page: 1,
          ordering
        })
      )
    });
  };

  const onResetFilter = () => {
    navigate({
      pathname,
      search: queryString.stringify(
        getOnlyValidObj({
          ...defaultSearchParams.filter,
          search: searchParams.search,
          page_size: searchParams.page_size,
          ordering: searchParams.ordering,
          page: 1
        })
      )
    });
  };

  useEffect(() => {
    const cookiePageSize = Cookies.get("pageSizeContact");
    const newSearchParams = queryString.parse(searchProperties);
    const page = newSearchParams.page ? +newSearchParams.page - 1 : defaultSearchParams.page;
    const search = newSearchParams.search
      ? (newSearchParams.search as string)
      : defaultSearchParams.search;
    const ordering = newSearchParams.ordering
      ? (newSearchParams.ordering as string)
      : defaultSearchParams.ordering;
    const page_size = newSearchParams.page_size
      ? +newSearchParams.page_size
      : cookiePageSize
        ? +cookiePageSize
        : defaultSearchParams.page_size;
    const contract_type = newSearchParams.contract_type
      ? getNumberArrayFromSearchParam(newSearchParams.contract_type)
      : defaultSearchParams.filter?.contract_type;
    const ord_account = newSearchParams.ord_account
      ? getNumberArrayFromSearchParam(newSearchParams.ord_account)
      : defaultSearchParams.filter?.ord_account;
    const combined_status = newSearchParams.combined_status
      ? getNumberArrayFromSearchParam(newSearchParams.combined_status)
      : defaultSearchParams.filter?.combined_status;
    const client_role = newSearchParams.client_role
      ? getNumberArrayFromSearchParam(newSearchParams.client_role)
      : defaultSearchParams.filter?.client_role;
    const contractor_role = newSearchParams.contractor_role
      ? getNumberArrayFromSearchParam(newSearchParams.contractor_role)
      : defaultSearchParams.filter?.contractor_role;
    const client = newSearchParams.client
      ? +newSearchParams.client
      : defaultSearchParams.filter?.client;
    const contractor = newSearchParams.contractor
      ? +newSearchParams.contractor
      : defaultSearchParams.filter?.contractor;
    const participant = newSearchParams.participant
      ? getStringFromSearchParam(newSearchParams.participant)
      : defaultSearchParams.filter?.participant;
    setSearchParams({
      page,
      search,
      ordering,
      page_size,
      filter: {
        contract_type,
        ord_account,
        combined_status,
        client_role,
        contractor_role,
        client,
        contractor,
        participant
      }
    });
    dispatch(setContractLastSearchParams(searchProperties));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchProperties]);

  useEffect(() => {
    setExportLink(defaultExportLink);
  }, [exportIds]);

  if (contractsError) {
    return <div>Error</div>;
  }

  const contracts = contractsInfoList?.data?.results || ([] as Contract[]);

  return (
    <ListingLayout
      onFilterToggle={onFilterToggle}
      onSearchClick={onSearchClick}
      onPageChange={onPageChange}
      onPageSizeSelect={onPageSizeSelect}
      onResetFilter={onResetFilter}
      onOrderSelect={onOrderSelect}
      onResetPage={() => onPageChange(0)}
      currentSearchQuery={searchParams.search}
      addHref="#/contract/add"
      isDataLoading={contractsLoading}
      isFilterModalOpen={isFilterModalOpen}
      currentPageSize={searchParams.page_size}
      currentPage={searchParams.page}
      currentFilter={searchParams.filter}
      currentOrder={searchParams.ordering}
      count={contractsInfoList?.data?.count}
      actionsDropdownMenu={
        <ContractListingActions
          onExport={onExport}
          exportLink={exportLink}
          refetch={contractsRefetch}
        />
      }
      table={
        <ContractTable
          contracts={contracts}
          refetch={contractsRefetch}
          onExportIdCheck={onExportIdCheck}
          setExportIds={setExportIds}
          exportIds={exportIds}
          pageIds={contracts.filter(({ id }) => !!id).map(({ id }) => id as number)}
        />
      }
      orderVariants={orderVariants}
      filter={
        <ContractFilter
          filter={searchParams.filter}
          filterClear={defaultSearchParams.filter}
          onFilterClick={onFilterClick}
          onClose={onFilterToggle}
        />
      }
      searchPlaceholder="Введите название ООО, ИНН, комментарий или номер договора"
    />
  );
};

import { Contract } from "models";
import { TContractFilter } from "types/TContractFilter";
import { TSearchParamsPropsNoFilter } from "types/TSearchParams";
import { getSearchParams } from "utils/getSearchParams";
import { APIListData } from "./types";

export interface IPropsContract extends TSearchParamsPropsNoFilter {
  filter?: TContractFilter;
}

export const getContractList = (props?: IPropsContract): Promise<APIListData<Contract>> => {
  return fetch(
    props === undefined ? `/api/v1/contract/` : `/api/v1/contract/?${getSearchParams(props)}`,
    {
      mode: "cors",
      headers: {
        "X-Requested-With": "XMLHttpRequest"
      }
    }
  ).then((res) => {
    const result = res.json() as Promise<APIListData<Contract>>;
    return result;
  });
};

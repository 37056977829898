import ListingLayout from "Layout/ListingLayout";
import { getInvoiceList } from "api/invoice_list";
import { APIListData } from "api/types";
import Cookies from "js-cookie";
import { ID, Invoice } from "models";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setCloseModalNotNeeded } from "reducers/CloseModalSettings";
import { setInvoiceLastSearchParams } from "reducers/LastSearchParams";
import { TInvoiceFilter } from "types/TInvoiceFilter";
import createNotification from "ui/Notification";
import { OrderingVariant } from "ui/SortSelector";
import getLastPageNumber from "utils/getLastPageNumber";
import getNumberArrayFromSearchParam from "utils/getNumberArrayFromSearchParam";
import getOnlyValidObj from "utils/getOnlyValidObj";
import getSearchParams from "utils/getSearchParams";
import getStringFromSearchParam from "utils/getStringFromSearchParam";
import useIsOpenControl from "utils/useIsOpenControl";
import { InvoiceFilter } from "./components/filter";
import { InvoiceListingActions } from "./components/listingActions";
import { InvoiceTable } from "./components/table";
import { defaultExportLink, defaultSearchParams } from "./constants";

const orderVariants: OrderingVariant[] = [
  {
    order: "id",
    title: "ID ↑"
  },
  {
    order: "-id",
    title: "ID ↓"
  },
  {
    order: "invoice_number",
    title: "№ акта ↑"
  },
  {
    order: "-invoice_number",
    title: "№ акта ↓"
  },
  {
    order: "invoice_date",
    title: "Дата акта ↑"
  },
  {
    order: "-invoice_date",
    title: "Дата акта ↓"
  }
];

export const InvoiceList = () => {
  const navigate = useNavigate();
  const { pathname, search: searchProperties } = useLocation();
  const dispatch = useDispatch();
  dispatch(setCloseModalNotNeeded());

  const [searchParams, setSearchParams] = useState(defaultSearchParams);

  const { isOpen: isModalOpen, toggle: modalOpenToggle } = useIsOpenControl();

  const [exportIds, setExportIds] = useState<ID[]>([]);
  const [exportLink, setExportLink] = useState<string>(defaultExportLink);

  const {
    isLoading: invoicesLoading,
    error: invoicesError,
    data: invoicesInfoList,
    refetch: invoicesRefetch
  } = useQuery<APIListData<Invoice>, Error>({
    queryKey: [
      "invoice_list",
      searchParams.page,
      searchParams.page_size,
      searchParams.search,
      searchParams.filter,
      searchParams.ordering
    ],
    queryFn: () =>
      getInvoiceList({
        page: searchParams.page,
        size: searchParams.page_size,
        search: searchParams.search,
        filter: searchParams.filter,
        ordering: searchParams.ordering
      })
  });

  const onPageChange = (page: number) => {
    navigate({
      pathname,
      search: queryString.stringify(
        getOnlyValidObj({
          ...searchParams.filter,
          search: searchParams.search,
          page_size: searchParams.page_size,
          ordering: searchParams.ordering,
          page: page + 1
        })
      )
    });
  };

  const onSearchClick = (query: string) => {
    navigate({
      pathname,
      search: queryString.stringify(
        getOnlyValidObj({
          ...searchParams.filter,
          page_size: searchParams.page_size,
          ordering: searchParams.ordering,
          search: query ? query : undefined,
          page: 1
        })
      )
    });
  };

  const onPageSizeSelect = (page_size: number) => {
    Cookies.set("pageSizeInvoice", page_size.toString());
    const lastPageNumber = getLastPageNumber(invoicesInfoList?.data?.count || 0, page_size);
    const page = lastPageNumber < searchParams.page ? (lastPageNumber || 1) - 1 : searchParams.page;
    navigate({
      pathname,
      search: queryString.stringify(
        getOnlyValidObj({
          ...searchParams.filter,
          search: searchParams.search,
          ordering: searchParams.ordering,
          page,
          page_size
        })
      )
    });
  };

  const onOrderSelect = (ordering: string) => {
    navigate({
      pathname,
      search: queryString.stringify(
        getOnlyValidObj({
          ...searchParams.filter,
          search: searchParams.search,
          page_size: searchParams.page_size,
          page: 1,
          ordering
        })
      )
    });
  };

  const onFilterClick = (filter: TInvoiceFilter) => {
    navigate({
      pathname,
      search: queryString.stringify(
        getOnlyValidObj({
          search: searchParams.search,
          page_size: searchParams.page_size,
          ordering: searchParams.ordering,
          page: 1,
          ...filter
        })
      )
    });
  };

  const onExportIdCheck = (checkedId: ID) => {
    setExportIds((prev) =>
      prev.includes(checkedId) ? prev.filter((item) => item !== checkedId) : [...prev, checkedId]
    );
  };

  const onExport = () => {
    if (exportIds.length) {
      setExportLink(`${defaultExportLink}?${getSearchParams({ filter: { id: exportIds } })}`);
    } else {
      createNotification("warning", "Ничего не выбрано, в файл попадут все данные", 3000);
    }
  };

  const onResetFilter = () => {
    navigate({
      pathname,
      search: queryString.stringify(
        getOnlyValidObj({
          ...defaultSearchParams.filter,
          search: searchParams.search,
          page_size: searchParams.page_size,
          ordering: searchParams.ordering,
          page: 1
        })
      )
    });
  };

  useEffect(() => {
    const cookiePageSize = Cookies.get("pageSizeInvoice");
    const newSearchParams = queryString.parse(searchProperties);
    const page = newSearchParams.page ? +newSearchParams.page - 1 : defaultSearchParams.page;
    const search = newSearchParams.search
      ? (newSearchParams.search as string)
      : defaultSearchParams.search;
    const ordering = newSearchParams.ordering
      ? (newSearchParams.ordering as string)
      : defaultSearchParams.ordering;
    const page_size = newSearchParams.page_size
      ? +newSearchParams.page_size
      : cookiePageSize
        ? +cookiePageSize
        : defaultSearchParams.page_size;
    const ord_account = newSearchParams.ord_account
      ? getNumberArrayFromSearchParam(newSearchParams.ord_account)
      : defaultSearchParams.filter?.ord_account;
    const combined_status = newSearchParams.combined_status
      ? getNumberArrayFromSearchParam(newSearchParams.combined_status)
      : defaultSearchParams.filter?.combined_status;
    const start_date = newSearchParams.start_date
      ? getStringFromSearchParam(newSearchParams.start_date)
      : defaultSearchParams.filter?.start_date;
    const invoice_number = newSearchParams.invoice_number
      ? getStringFromSearchParam(newSearchParams.invoice_number)
      : defaultSearchParams.filter?.invoice_number;
    const end_date = newSearchParams.end_date
      ? getStringFromSearchParam(newSearchParams.end_date)
      : defaultSearchParams.filter?.end_date;
    const contract = newSearchParams.contract
      ? getNumberArrayFromSearchParam(newSearchParams.contract)
      : defaultSearchParams.filter?.contract;
    setSearchParams({
      page,
      search,
      ordering,
      page_size,
      filter: {
        ord_account,
        combined_status,
        start_date,
        end_date,
        invoice_number,
        contract
      }
    });
    dispatch(setInvoiceLastSearchParams(searchProperties));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchProperties]);

  useEffect(() => {
    setExportLink(defaultExportLink);
  }, [exportIds]);

  if (invoicesError) {
    return <div>Error</div>;
  }

  const invoices = invoicesInfoList?.data.results || [];

  return (
    <ListingLayout
      onFilterToggle={modalOpenToggle}
      onSearchClick={onSearchClick}
      onPageChange={onPageChange}
      onOrderSelect={onOrderSelect}
      onPageSizeSelect={onPageSizeSelect}
      onResetFilter={onResetFilter}
      onResetPage={() => onPageChange(0)}
      currentSearchQuery={searchParams.search}
      addHref="#/invoice/add"
      isDataLoading={invoicesLoading}
      isFilterModalOpen={isModalOpen}
      currentPageSize={searchParams.page_size}
      currentPage={searchParams.page}
      currentOrder={searchParams.ordering}
      currentFilter={searchParams.filter}
      count={invoicesInfoList?.data?.count}
      actionsDropdownMenu={
        <InvoiceListingActions
          onExport={onExport}
          exportLink={exportLink}
          refetch={invoicesRefetch}
        />
      }
      table={
        <InvoiceTable
          invoices={invoices}
          refetch={invoicesRefetch}
          onExportIdCheck={onExportIdCheck}
          setExportIds={setExportIds}
          exportIds={exportIds}
          pageIds={invoices.filter(({ id }) => !!id).map(({ id }) => id as number)}
        />
      }
      orderVariants={orderVariants}
      filter={
        <InvoiceFilter
          filter={searchParams.filter}
          clearFilter={defaultSearchParams.filter}
          onFilterClick={onFilterClick}
          onClose={modalOpenToggle}
        />
      }
      searchPlaceholder="Введите, название ООО, ИНН или номер договора"
    />
  );
};

import { NEW_Creative } from "models";
import { TCreativeFilter } from "types/TCreativeFilter";
import { TSearchParamsPropsNoFilter } from "types/TSearchParams";
import getSearchParams from "utils/getSearchParams";
import { APIListData } from "./types";

export interface IPropsCreative extends TSearchParamsPropsNoFilter {
  filter?: TCreativeFilter;
}

export const getCreativeNewList = (props?: IPropsCreative): Promise<APIListData<NEW_Creative>> => {
  return fetch(
    props === undefined
      ? `/api/v1/creative_new/`
      : `/api/v1/creative_new/?${getSearchParams(props)}`,
    {
      mode: "cors",
      headers: {
        "X-Requested-With": "XMLHttpRequest"
      }
    }
  ).then((res) => {
    const result = res.json() as Promise<APIListData<NEW_Creative>>;
    return result;
  });
};

import { Participant } from "models";
import { TParticipantFilter } from "types/TParticipantFilter";
import { TSearchParamsPropsNoFilter } from "types/TSearchParams";
import { getSearchParams } from "utils/getSearchParams";
import { APIListData } from "./types";

export interface IPropsParticipant extends TSearchParamsPropsNoFilter {
  filter?: TParticipantFilter;
}

export const getParticipantList = (
  props?: IPropsParticipant
): Promise<APIListData<Participant>> => {
  return fetch(
    props === undefined ? `/api/v1/participant/` : `/api/v1/participant/?${getSearchParams(props)}`,
    {
      mode: "cors",
      headers: {
        "X-Requested-With": "XMLHttpRequest"
      }
    }
  ).then((res) => {
    const result = res.json() as Promise<APIListData<Participant>>;
    return result;
  });
};

import { getCreativeGroupNewItem } from "api/creative_group_new_item";
import { patchCreativeNew } from "api/creative_new_patch";
import { AxiosError } from "axios";
import EACSOptions from "constants/EACSOptions";
import creative_form from "dictionaries/creative_form.json";
import creative_type from "dictionaries/creative_type.json";
import {
  ID,
  NEW_Contract,
  NEW_CreativeGroupAdvertismentForm,
  NEW_CreativeGroupAdvertismentType
} from "models";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { CheckBoxField } from "ui/CheckBox";
import { DatePickerField } from "ui/DatePicker";
import FakeInput from "ui/FakeInput";
import { InputField } from "ui/Input";
import createNotification from "ui/Notification";
import { PredictiveSearchField } from "ui/PredictiveSearch";
import { SelectField } from "ui/Select";
import { TextAreaField } from "ui/TextArea";
import createOptions from "utils/createOptions";
import getIsEacsRequired from "utils/getIsEacsRequired";
import getTitleFromDict from "utils/getTitleFromDict";
import onErrorMutation from "utils/onErrorMutation";
import useSearchCreativeGroupMutation from "./hooks/useSearchCreativeGroupMutation";

export type TPecularitiesValue = "is_social" | "is_native";

export type TDefaultValuesGroupEditor = {
  new_group?: boolean;
  group_id?: ID;
  label?: string;
  advertisement_type?: NEW_CreativeGroupAdvertismentType;
  start_date?: string;
  end_date?: string;
  peculiarities?: TPecularitiesValue;
  eacs?: number[];
  target_audience?: string;
  description?: string;
};

type Props = {
  isOpen: boolean;
  toggle: () => void;
  creativeTitle: string;
  creativeId?: ID;
  groupId?: ID;
  isDraft?: boolean;
  refetch?: () => Promise<any>;
};

const allCreativeFormVariants = Object.keys(creative_form).map(
  (v) => +v as NEW_CreativeGroupAdvertismentForm
);

const ChangeGroupModal = ({
  isOpen,
  toggle,
  creativeTitle,
  isDraft,
  creativeId,
  groupId,
  refetch
}: Props) => {
  const creativeAdvertisementsTypesOptions = createOptions(creative_type);

  const [initialContract, setInitialContract] = useState<NEW_Contract>({});
  const [finalContract, setFinalContract] = useState<NEW_Contract>({});
  const [advertisementForm, setAdvertisementForm] = useState<
    NEW_CreativeGroupAdvertismentForm | undefined
  >();
  const [groupIdOptionDisableConditions, setGroupIdOptionDisableConditions] = useState<
    { advertisement_form: NEW_CreativeGroupAdvertismentForm }[]
  >([]);
  const [isEacsRequired, setIsEacsRequired] = useState(false);

  const {
    control,
    watch,
    formState: { errors },
    setValue,
    handleSubmit,
    setError
  } = useForm({
    defaultValues: {
      new_group: undefined,
      group_id: undefined,
      label: undefined,
      advertisement_type: undefined,
      start_date: undefined,
      end_date: undefined,
      peculiarities: undefined,
      eacs: undefined,
      target_audience: undefined,
      description: undefined
    } as TDefaultValuesGroupEditor
  });

  const { mutate: edit } = useMutation({
    mutationFn: patchCreativeNew,
    onSuccess: () => {
      createNotification("success", "Группа успешно изменена");
      toggle();
      refetch && void refetch();
    },
    onError: (err) => onErrorMutation(err as AxiosError, setError)
  });

  useEffect(() => {
    if (!groupId || !isOpen) return;

    getCreativeGroupNewItem(groupId)
      .then((res) => {
        if (res.status) {
          const data = res.data;

          setInitialContract(data.extra_fields?.initial_contract || {});
          const currentFinalContract = data.extra_fields?.final_contract || {};
          setFinalContract(currentFinalContract);
          setIsEacsRequired(getIsEacsRequired(currentFinalContract?.client?.legal_form));

          const af = data.advertisement_form || undefined;
          setAdvertisementForm(af);
          const newGroupIdOptionDisableConditions = allCreativeFormVariants
            .filter((v) => v !== af)
            .map((v) => ({ advertisement_form: v }));
          setGroupIdOptionDisableConditions(newGroupIdOptionDisableConditions);

          setValue("group_id", data.id);
          setValue("label", data.label);
          setValue("advertisement_type", data.advertisement_type);
          setValue("start_date", data.start_date);
          setValue("end_date", data.end_date);
          setValue(
            "peculiarities",
            data.is_native ? "is_native" : data.is_social ? "is_social" : undefined
          );
          setValue("eacs", data.eacs);
          setValue("target_audience", data.target_audience);
          setValue("description", data.description);
        } else {
          console.log(res.error_message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [groupId, setValue, isOpen]);

  const [searchCreativeGroup, setSearchCreativeGroup] = useState<string | undefined>("");

  const {
    mutate: creativeGroupRequest,
    isLoading: creativeGroupLoading,
    data: creativeGroupResponse
  } = useSearchCreativeGroupMutation({
    searchCreativeGroup,
    currentGroupId: watch("group_id"),
    initialContractId: initialContract?.id,
    finalContractId: finalContract?.id,
    advertisementForm
  });

  const changeGroupFunction = () => {
    const is_native =
      watch("peculiarities") === undefined ? undefined : watch("peculiarities") === "is_native";
    const is_social =
      watch("peculiarities") === undefined ? undefined : watch("peculiarities") === "is_social";
    const group_id = watch("group_id");

    const group = watch("new_group")
      ? {
          label: watch("label"),
          start_date: watch("start_date"),
          end_date: watch("end_date"),
          initial_contract: initialContract.id,
          final_contract: finalContract.id,
          advertisement_type: watch("advertisement_type"),
          advertisement_form: advertisementForm,
          description: watch("description"),
          target_audience: watch("target_audience"),
          is_native,
          is_social,
          eacs: watch("eacs")
        }
      : undefined;

    if (group_id && !group && creativeId) {
      edit({
        id: creativeId,
        creative: {
          group_id
        }
      });
    }

    if (group && !group_id && creativeId) {
      edit({
        id: creativeId,
        creative: {
          group
        }
      });
    }
  };

  const onSubmit = handleSubmit(changeGroupFunction, (err) => {
    console.log(err);
  });

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered className="p-0 modal-shadow-none">
      <ModalHeader>Сменить группу для креатива {creativeTitle}</ModalHeader>
      <Form
        onSubmit={(event) => {
          event.preventDefault();
          void onSubmit(event);
        }}
      >
        <ModalBody>
          <FakeInput
            title="Номер изначального договора"
            texts={[
              `Номер договора: ${initialContract?.number || `[${initialContract?.id}]`}`,
              `Заказчик: ${initialContract?.client?.name || `[${initialContract?.client?.id}]`}`,
              `Исполнитель: ${
                initialContract?.contractor?.name || `[${initialContract?.contractor?.id}]`
              }`
            ]}
            infoText={{
              id: "initial_contract_number_fake_input",
              text: "Изменение договора доступно на страницах редактирования креатива или группы креативов"
            }}
            className="mb-3"
          />
          <CheckBoxField
            name="new_group"
            title="Новая группа"
            control={control}
            isChecked={!!watch("new_group")}
            extraAction={() => {
              setValue("group_id", undefined);
              setValue("label", undefined);
              setValue("advertisement_type", undefined);
              setValue("start_date", undefined);
              setValue("end_date", undefined);
              setValue("peculiarities", undefined);
              setValue("eacs", undefined);
              setValue("target_audience", undefined);
              setValue("description", undefined);
            }}
          />
          <PredictiveSearchField
            name="group_id"
            title="Список доступных групп креативов"
            optionKey={["id", "label", "advertisement_form"]}
            optionKeyLabels={["ID", "Название", "hidden"]}
            optionDisableCondition={groupIdOptionDisableConditions}
            inputValue={searchCreativeGroup}
            setInputValue={setSearchCreativeGroup}
            control={control}
            isLoading={creativeGroupLoading}
            request={creativeGroupRequest}
            response={creativeGroupResponse}
            isRequired={!watch("new_group")}
            clearable
            onChange={(newVal) => {
              const selectedGroup =
                newVal && creativeGroupResponse?.status
                  ? creativeGroupResponse?.data.results.find((item) => item.id === newVal)
                  : undefined;

              setValue("group_id", selectedGroup?.id || undefined);
              setValue("label", selectedGroup?.label || undefined);
              setValue("advertisement_type", selectedGroup?.advertisement_type || undefined);
              setValue("start_date", selectedGroup?.start_date || undefined);
              setValue("end_date", selectedGroup?.end_date || undefined);
              setValue(
                "peculiarities",
                selectedGroup?.is_native
                  ? "is_native"
                  : selectedGroup?.is_social
                    ? "is_social"
                    : undefined
              );
              setValue("eacs", selectedGroup?.eacs || undefined);
              setValue("target_audience", selectedGroup?.target_audience || undefined);
              setValue("description", selectedGroup?.description || undefined);
            }}
            disabled={watch("new_group")}
            infoText="Креатив можно переместить в рамках текущего договора в другую существующую группу c такими же изначальным и финальным договорами, формой распространения или создать новую группу"
          />
          <InputField
            name="label"
            title="Наименование группы"
            control={control}
            errors={errors}
            disabled={!watch("new_group")}
            isRequired={watch("new_group")}
            maxLength={2000}
          />
          <SelectField
            name="advertisement_type"
            title="Тип рекламной кампании"
            control={control}
            errors={errors}
            options={creativeAdvertisementsTypesOptions}
            disabled={!watch("new_group")}
            isRequired={watch("new_group")}
          />
          <FakeInput
            title="Форма распространения"
            texts={[getTitleFromDict(advertisementForm, creative_form)]}
            className="mb-3"
            isStared
          />
          <DatePickerField
            name="start_date"
            title="Дата начала периода размещения"
            control={control}
            errors={errors}
            returnFormat="yyyy-MM-dd"
            isRequired={!!watch("start_date") || !!watch("end_date")}
            disabled={!watch("new_group")}
          />
          <DatePickerField
            name="end_date"
            title="Дата окончания периода размещения"
            control={control}
            errors={errors}
            returnFormat="yyyy-MM-dd"
            isRequired={!!watch("start_date") || !!watch("end_date")}
            disabled={!watch("new_group")}
          />
          <SelectField
            name="peculiarities"
            title="Особенности рекламной кампании"
            control={control}
            errors={errors}
            options={[
              { value: "is_social", label: "Социальная" },
              { value: "is_native", label: "Нативная" }
            ]}
            disabled={!watch("new_group")}
          />
          <SelectField
            name="eacs"
            title="Коды ОКВЭД"
            control={control}
            errors={errors}
            options={EACSOptions}
            chunkSize={10}
            isMulti
            infoText="Коды ОКВЭД рекламируемого товара, выберите один или несколько из справочника."
            disabled={!watch("new_group")}
            isRequired={isEacsRequired}
          />
          <InputField
            name="target_audience"
            title="Параметры целевой аудитории"
            control={control}
            errors={errors}
            disabled={!watch("new_group")}
          />
          <TextAreaField
            name="description"
            title="Общее описание объекта рекламирования"
            control={control}
            errors={errors}
            disabled={!watch("new_group")}
            infoText={
              <>
                <p>Укажите:</p>
                <ul>
                  <li>Бренд (или несколько брендов) рекламируемых товаров или услуг;</li>
                  <li>Вид товара / услуги;</li>
                  <li>Дополнительную информацию.</li>
                </ul>
                <p>Примеры:</p>
                <ul>
                  <li>Tide. Стиральный порошок. Годовой запас в подарок.</li>
                  <li>
                    Отель «Ромашка». Бронирование номеров.Гостиница, отель в Подмосковье на
                    выходные.
                  </li>
                </ul>
              </>
            }
            isRequired
            maxLength={1000}
          />
          {isDraft ? (
            <>
              <p className="mb-0 text-primary">
                После сохранения изменений, креативы в&nbsp;статусе &quot;Черновик&quot; обновляются
                в&nbsp;сервисе ADVM.
              </p>
              <p className="mb-0 text-primary">
                Для отправки креатива на регистрацию в&nbsp;ЕРИР необходимо выполнить это действие
                из&nbsp;карточки редактирования креатива.
              </p>
            </>
          ) : (
            <p className="mb-0 text-primary">
              После сохранения изменений, креативы со&nbsp;статусами &quot;Активный&quot;,
              &quot;Ошибка регистрации&quot; или &quot;Зарегистрировано в&nbsp;ЕРИР&quot; будут
              автоматически отправлены на регистрацию в&nbsp;ЕРИР.
            </p>
          )}
        </ModalBody>
        <ModalFooter className="justify-content-center">
          <Button type="button" color="secondary" onClick={toggle}>
            Отменить
          </Button>
          <Button type="submit" color="primary">
            Сохранить
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default ChangeGroupModal;

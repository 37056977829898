import { format } from "date-fns";
import omit from "lodash/omit";
import { Contract } from "models";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import { StateProps } from "reducers";
import { scrollToError } from "utils/scrollToError";
import { ContractForm } from "./components/form";
import useAddContract from "./hooks/useAddContract";

const ContractAddForm = () => {
  const contractLastSearch = useSelector((state: StateProps) => state.LastSearchParams.contract);
  const [isDraft, setIsDraft] = useState(false);

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setError,
    setValue,
    resetField
  } = useForm();

  const add = useAddContract(contractLastSearch, watch, setError);

  const onSubmit = handleSubmit(
    (_, event) => {
      const submitter = (event?.nativeEvent as SubmitEvent).submitter as HTMLButtonElement;
      if (submitter.value) {
        const status = +submitter.value as 1 | 2;

        const {
          client,
          contractor,
          subject_type,
          contract_number,
          contract_date,
          creatives_registrar
        } =
          watch("contract_type") == 4
            ? {
                client: watch("self_ad_participant") as Contract["client"],
                contractor: watch("self_ad_participant") as Contract["contractor"],
                subject_type: 1 as Contract["subject_type"], // 'Иное'
                contract_number: "" as Contract["contract_number"],
                contract_date: format(new Date(), "yyyy-MM-dd") as Contract["contract_date"],
                creatives_registrar: 2 as Contract["creatives_registrar"] // 'Исполнитель'
              }
            : {
                client: watch("client") as Contract["client"],
                contractor: watch("contractor") as Contract["contractor"],
                subject_type: watch("subject_type") as Contract["subject_type"],
                contract_number: (watch("contract_number") || "") as Contract["contract_number"],
                contract_date: watch("contract_date") as Contract["contract_date"],
                creatives_registrar: watch("creatives_registrar") as Contract["creatives_registrar"]
              };

        const is_agent_acting_for_publisher =
          watch("is_agent_acting_for_publisher") !== undefined
            ? !!watch("is_agent_acting_for_publisher")
            : undefined;

        add({
          contract: {
            ...omit(watch(), ["noNumber", "self_ad_participant", "contractor_ord", "client_ord"]),
            vat_included: !!(watch("vat_included") as 0 | 1 | undefined),
            is_agent_acting_for_publisher,
            client,
            contractor,
            subject_type,
            contract_number,
            contract_date,
            creatives_registrar,
            status
          }
        });
      }
    },
    (err) => {
      if (err) {
        scrollToError(err);
      }
    }
  );

  const buttons = (
    <div className="d-flex gap-3 justify-content-between">
      <Button type="submit" color="primary" outline value={2} onClick={() => setIsDraft(true)}>
        Сохранить черновик
      </Button>
      <div className="d-flex gap-3">
        <Button href={`#/contract/${contractLastSearch}`} tag="a" color="primary" outline>
          Отменить
        </Button>
        <Button type="submit" color="primary" value={1} onClick={() => setIsDraft(false)}>
          Добавить
        </Button>
      </div>
    </div>
  );

  return (
    <div>
      <h1>Добавление договора</h1>
      <ContractForm
        control={control}
        errors={errors}
        onSubmit={onSubmit}
        buttons={buttons}
        watch={watch}
        setValue={setValue}
        isDraft={isDraft}
        resetField={resetField}
      />
    </div>
  );
};

export default ContractAddForm;

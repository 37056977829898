import { deleteContract } from "api/contract_delete";
import { ID } from "models";
import { FieldValues, UseFormWatch } from "react-hook-form";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import onDeleteMutation from "utils/onDeleteMutation";

const useDeleteContract = (
  id: ID,
  contractLastSearch: string,
  watch: UseFormWatch<FieldValues>
) => {
  const navigate = useNavigate();

  const { mutate } = useMutation({
    mutationFn: deleteContract,
    onSuccess: (response) =>
      onDeleteMutation({
        response,
        href: `/contract/${contractLastSearch}`,
        navigate,
        successMessage: `Договор ${
          watch("contract_number") && watch("contract_number") !== ""
            ? `№${watch("contract_number")}`
            : `[${id}]`
        } удален`
      })
  });

  return mutate;
};

export default useDeleteContract;

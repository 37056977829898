import { TInvoiceFilter } from "types/TInvoiceFilter";
import { TSearchParamsRequired } from "types/TSearchParams";

export const defaultSearchParams: TSearchParamsRequired<TInvoiceFilter> = {
  search: "",
  page: 0,
  page_size: 10,
  ordering: "-id",
  filter: {
    ord_account: [],
    combined_status: [],
    start_date: undefined,
    end_date: undefined,
    invoice_number: undefined,
    contract: []
  }
};

export const defaultExportLink = "/api/v1/invoice/export/xls";

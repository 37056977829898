import invoice_status from "dictionaries/invoice_status.json";
import participant_client_roles from "dictionaries/participant_client_roles.json";
import participant_contractor_roles from "dictionaries/participant_contractor_roles.json";
import { ID, Invoice } from "models";
import { useNavigate } from "react-router-dom";
import { ListGroupItem } from "reactstrap";
import { ActionSelectorProps } from "ui/ActionSelector";
import ListingCard from "ui/ListingCard";
import ListingCardsWrapper from "ui/ListingCardsWrapper";
import ListingTable from "ui/ListingTable";
import ListingTableRow from "ui/ListingTableRow";
import StatusCell from "ui/StatusDisplay/StatusCell";
import StatusInline from "ui/StatusDisplay/StatusInline";
import getDate from "utils/getDate";
import getTitleFromDict from "utils/getTitleFromDict";

type Props = {
  invoices: Invoice[];
};

export const StatisticsTable = ({ invoices }: Props) => {
  const navigate = useNavigate();

  const onRowDoubleClick = (id: ID) => navigate(`/invoice/${id}`);

  const actions: ActionSelectorProps[] = [
    {
      title: "Редактировать",
      onClick: onRowDoubleClick
    },
    {
      title: "Скрыть из моего списка",
      onClick: (e) => console.log(e)
    }
  ];

  return (
    <>
      <ListingTable
        theadList={[
          "Заказчик",
          "Роль заказчика",
          "Исполнитель",
          "Роль исполнителя",
          "№ договора",
          "Дата договора",
          "Период",
          "Статус",
          "Количество креативов",
          "Количество строк статистики",
          "Фактическое число показов",
          "Общая стоимость услуг"
        ]}
        tbody={invoices.map((invoice: Invoice) => (
          <ListingTableRow
            key={invoice.id}
            id={invoice.id}
            onRowDoubleClick={() => invoice.id && onRowDoubleClick(invoice.id)}
            actions={actions}
            list={
              <>
                <td>
                  {invoice.extra_fields?.invoice_contract?.client?.name}
                  <br />
                  {invoice.extra_fields?.invoice_contract?.client?.inn}
                </td>
                <td>
                  {getTitleFromDict(
                    invoice.extra_fields?.invoice_contract?.client?.role,
                    participant_client_roles
                  )}
                </td>
                <td>
                  {invoice.extra_fields?.invoice_contract?.contractor?.name}
                  <br />
                  {invoice.extra_fields?.invoice_contract?.contractor?.inn}
                </td>
                <td>
                  {getTitleFromDict(
                    invoice.extra_fields?.invoice_contract?.contractor?.role,
                    participant_contractor_roles
                  )}
                </td>
                <td>{invoice.extra_fields?.invoice_contract?.number}</td>
                <td>{getDate(invoice.extra_fields?.invoice_contract?.date)}</td>
                <td>{invoice.invoice_number}</td>
                <td>{getDate(invoice.invoice_date)}</td>
                <td>{invoice.amount}</td>
                <td>{getDate(invoice.start_date)}</td>
                <td>{getDate(invoice.end_date)}</td>
                <StatusCell data={invoice} dictionary={invoice_status} />
                <td>{invoice.extra_fields?.impressions_fact}</td>
              </>
            }
          />
        ))}
      />
      <ListingCardsWrapper>
        {invoices.map((invoice: Invoice) => (
          <ListingCard
            key={invoice.id}
            id={invoice.id}
            href={`/invoice/${invoice.id}`}
            actions={actions}
            list={
              <>
                <ListGroupItem>
                  Заказчик: {invoice.extra_fields?.invoice_contract?.client?.name}{" "}
                  {invoice.extra_fields?.invoice_contract?.client?.inn
                    ? `(${invoice.extra_fields?.invoice_contract?.client?.inn})`
                    : ""}
                </ListGroupItem>
                <ListGroupItem>
                  Роль заказчика:{" "}
                  {getTitleFromDict(
                    invoice.extra_fields?.invoice_contract?.client?.role,
                    participant_client_roles
                  )}
                </ListGroupItem>
                <ListGroupItem>
                  Исполнитель: {invoice.extra_fields?.invoice_contract?.contractor?.name}{" "}
                  {invoice.extra_fields?.invoice_contract?.contractor?.inn
                    ? `(${invoice.extra_fields?.invoice_contract?.contractor?.inn})`
                    : ""}
                </ListGroupItem>
                <ListGroupItem>
                  Роль исполнителя:{" "}
                  {getTitleFromDict(
                    invoice.extra_fields?.invoice_contract?.contractor?.role,
                    participant_contractor_roles
                  )}
                </ListGroupItem>
                <ListGroupItem>
                  № договора: {invoice.extra_fields?.invoice_contract?.number}
                </ListGroupItem>
                <ListGroupItem>
                  Дата договора: {getDate(invoice.extra_fields?.invoice_contract?.date)}
                </ListGroupItem>
                <ListGroupItem>Период: {invoice.invoice_number}</ListGroupItem>
                <ListGroupItem>
                  Статус: <StatusInline data={invoice} dictionary={invoice_status} />
                </ListGroupItem>
                <ListGroupItem>Количество креативов: {invoice.amount}</ListGroupItem>
                <ListGroupItem>
                  Количество строк статистики: {getDate(invoice.start_date)}
                </ListGroupItem>
                <ListGroupItem>
                  Фактическое число показов: {invoice.extra_fields?.impressions_fact}
                </ListGroupItem>
                <ListGroupItem>Общая стоимость услуг: </ListGroupItem>
              </>
            }
          />
        ))}
      </ListingCardsWrapper>
    </>
  );
};

import { getParticipant } from "api/participant_item";
import { APIData } from "api/types";
import { ID, Participant } from "models";
import { FieldValues, UseFormReset } from "react-hook-form";
import { useQuery } from "react-query";

const useLoadParticipant = (id: ID, reset: UseFormReset<FieldValues>) => {
  const { isLoading, error, data } = useQuery<APIData<Participant>, Error>({
    queryKey: ["participant_list", id],
    queryFn: () => getParticipant(id),
    enabled: !!id,
    onSuccess: (loadedInfo) => {
      if (loadedInfo.status) {
        reset(loadedInfo.data);
      }
    }
  });
  return {
    isLoading,
    error,
    status: (data?.data.status === 2 ? "draft" : "active") as "draft" | "active"
  };
};

export default useLoadParticipant;

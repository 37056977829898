import {
  useSearchFinalContractMutation,
  useSearchInitialContractMutation,
  useSearchParticipantsMutation
} from "Components/ChangeContractModal/hooks";
import { Contract, ID } from "models";
import { FC, ReactElement, useState } from "react";
import {
  Control,
  FieldErrors,
  UseFormClearErrors,
  UseFormSetValue,
  UseFormWatch
} from "react-hook-form/dist/types";
import { useMutation } from "react-query";
import { CheckBoxField } from "ui/CheckBox";
import { PredictiveSearchField } from "ui/PredictiveSearch";
import { searchMutationContract } from "ui/PredictiveSearch/helpers";
import getErrorText from "utils/getErrorText";
import shortTimeout from "utils/shortTimeout";
import { ICreativeAddForm } from "../../../types";

interface Props {
  control: Control<ICreativeAddForm, any>;
  setValue: UseFormSetValue<ICreativeAddForm>;
  errors: FieldErrors<ICreativeAddForm>;
  watch: UseFormWatch<ICreativeAddForm>;
  clearErrors: UseFormClearErrors<ICreativeAddForm>;
  isDraft: boolean;
}

export const CreativeFormFirstStep: FC<Props> = ({
  setValue,
  control,
  errors,
  watch,
  clearErrors
}: Props): ReactElement => {
  // Роль заказчика Рекламодатель
  const [isClientAdvertiser, setIsClientAdvertiser] = useState(false);

  // Контрагент саморекламы (self_ad_client)
  const [searchSelfAdContractsValue, setSearchSelfAdContractsValue] = useState<string | undefined>(
    undefined
  );

  // Заказчик по прямому договору (client)
  const [searchClientParticipantsValue, setSearchClientParticipantsValue] = useState<
    string | undefined
  >(undefined);

  // Исполнитель по прямому договору (contractor)
  const [searchContractorParticipantsValue, setSearchContractorParticipantsValue] = useState<
    string | undefined
  >(undefined);

  // Номер прямого договора (final_contract)
  const [searchContractsValue, setSearchContractsValue] = useState<string | undefined>(undefined);

  // ИНН заказчика по изначальному договору (тип рекламодатель) (client_initial)
  const [searchClientInitialParticipantsValue, setSearchClientInitialParticipantsValue] = useState<
    string | undefined
  >(undefined);

  // Номер изначального договора (initial_contract)
  const [searchInitialContractsValue, setSearchInitialContractsValue] = useState<
    string | undefined
  >(undefined);
  const [initialContractsIds, setInitialContractsIds] =
    useState<Contract["initial_contracts"]>(undefined);

  const {
    mutate: selfAdContractsRequest,
    isLoading: selfAdContractsLoading,
    data: selfAdContractsResponse
  } = useMutation({
    mutationFn: searchMutationContract(searchSelfAdContractsValue, null, {
      filter: {
        contract_type: [4]
      }
    })
  });

  const {
    mutate: clientParticipantsRequest,
    isLoading: clientParticipantsLoading,
    data: clientParticipantsResponse
  } = useSearchParticipantsMutation(searchClientParticipantsValue, watch("client"));

  const {
    mutate: contractorParticipantsRequest,
    isLoading: contractorParticipantsLoading,
    data: contractorParticipantsResponse
  } = useSearchParticipantsMutation(searchContractorParticipantsValue, watch("contractor"));

  const {
    mutate: contractsRequest,
    isLoading: contractsLoading,
    data: contractsResponse
  } = useSearchFinalContractMutation(searchContractsValue, watch("client"), watch("contractor"));

  const {
    mutate: clientInitialParticipantsRequest,
    isLoading: clientInitialParticipantsLoading,
    data: clientInitialParticipantsResponse
  } = useSearchParticipantsMutation(searchClientInitialParticipantsValue, watch("client_initial"));

  const {
    mutate: initialContractsRequest,
    isLoading: initialContractsLoading,
    data: initialContractsResponse
  } = useSearchInitialContractMutation(
    searchInitialContractsValue,
    watch("client_initial"),
    initialContractsIds
  );

  return (
    <>
      <p className="fs-4">Шаг 1 из 3: Создание цепочки договоров</p>

      <div className="row">
        <div className="col">
          <CheckBoxField
            name="self_ad_contract"
            title="Креативы к договору саморекламы"
            control={control}
            isChecked={watch("self_ad_contract") as boolean}
            extraAction={() => {
              setValue("client", undefined);
              setValue("contractor", undefined);
              setValue("client_initial", undefined);
              setValue("self_ad_participant", undefined);
              setValue("final_contract", undefined);
              setValue("initial_contract", undefined);
            }}
          />
        </div>
      </div>

      {watch("self_ad_contract") ? (
        <div className="row">
          <div className="col">
            <PredictiveSearchField
              name="self_ad_participant"
              title="Контрагент саморекламы"
              optionKey={[
                "extra_fields.client.id",
                "extra_fields.client.name",
                "extra_fields.client.inn",
                "id",
                "status"
              ]}
              optionKeyLabels={["ID", "Название", "ИНН", "ID договора", "hidden"]}
              inputValue={searchSelfAdContractsValue}
              setInputValue={setSearchSelfAdContractsValue}
              control={control}
              errors={errors}
              errorText={getErrorText(errors, "self_ad_participant", "Должно быть заполнено.")}
              isLoading={selfAdContractsLoading}
              request={selfAdContractsRequest}
              response={selfAdContractsResponse}
              optionDisableCondition={{ status: 2 }}
              infoText="Выберите контрагента с активным договором саморекламы"
              clearable
              isRequired
              onChange={(newVal: ID | undefined) => {
                if (!newVal) {
                  setValue("client", undefined);
                  setValue("contractor", undefined);
                  setValue("client_initial", undefined);
                  setValue("self_ad_participant", undefined);
                  setValue("final_contract", undefined);
                  setValue("initial_contract", undefined);
                  setSearchSelfAdContractsValue(undefined);
                  return;
                }

                const currentSelfAdContract = selfAdContractsResponse?.data.results.find(
                  (c) => c.id === newVal
                );

                if (currentSelfAdContract) {
                  setValue("client", currentSelfAdContract.id);
                  setValue("contractor", currentSelfAdContract.id);
                  setValue("client_initial", currentSelfAdContract.id);
                  setValue("final_contract", currentSelfAdContract.id);
                  setValue("initial_contract", currentSelfAdContract.id);
                }
              }}
            />
          </div>
        </div>
      ) : (
        <>
          <div className="row">
            <div className="col">
              <PredictiveSearchField
                name="client"
                title="Заказчик по прямому договору"
                optionKey={["id", "name", "inn", "status"]}
                optionKeyLabels={["ID", "Название", "ИНН", "hidden"]}
                inputValue={searchClientParticipantsValue}
                setInputValue={setSearchClientParticipantsValue}
                control={control}
                errors={errors}
                errorText={getErrorText(errors, "client", "Должно быть заполнено.")}
                isLoading={clientParticipantsLoading}
                request={clientParticipantsRequest}
                response={clientParticipantsResponse}
                optionDisableCondition={{ status: 2 }}
                infoText="Нельзя выбрать контрагентов, статус которых &lsquo;ЧЕРНОВИК&rsquo;"
                clearable
                isRequired
                onChange={(newVal: ID | undefined) => {
                  if (!newVal) {
                    setSearchClientParticipantsValue(undefined);
                  }
                  setSearchContractsValue(undefined);
                  setValue("final_contract", undefined);
                  clearErrors("final_contract");
                  shortTimeout(contractsRequest);
                }}
              />
            </div>
          </div>

          <div className="row">
            <div className="col">
              <PredictiveSearchField
                name="contractor"
                title="Исполнитель по прямому договору"
                optionKey={["id", "name", "inn", "status"]}
                optionKeyLabels={["ID", "Название", "ИНН", "hidden"]}
                inputValue={searchContractorParticipantsValue}
                setInputValue={setSearchContractorParticipantsValue}
                control={control}
                errors={errors}
                errorText={getErrorText(errors, "contractor", "Должно быть заполнено.")}
                isLoading={contractorParticipantsLoading}
                request={contractorParticipantsRequest}
                response={contractorParticipantsResponse}
                optionDisableCondition={{ status: 2 }}
                infoText="Нельзя выбрать контрагентов, статус которых &lsquo;ЧЕРНОВИК&rsquo;"
                clearable
                isRequired
                onChange={(newVal: ID | undefined) => {
                  if (!newVal) {
                    setSearchContractorParticipantsValue(undefined);
                  }
                  setSearchContractsValue(undefined);
                  setValue("final_contract", undefined);
                  clearErrors("final_contract");
                  shortTimeout(contractsRequest);
                }}
              />
            </div>
          </div>

          <div className="row">
            <div className="col">
              <PredictiveSearchField
                name="final_contract"
                title="Номер прямого договора"
                optionKey={[
                  "id",
                  "contract_number",
                  "extra_fields.client.name",
                  "extra_fields.contractor.name",
                  "status",
                  "contract_type"
                ]}
                optionKeyLabels={[
                  "ID",
                  "Номер контракта",
                  "Заказчик",
                  "Исполнитель",
                  "hidden",
                  "hidden"
                ]}
                inputValue={searchContractsValue}
                setInputValue={setSearchContractsValue}
                control={control}
                errors={errors}
                errorText={getErrorText(errors, "final_contract", "Должно быть заполнено.")}
                isLoading={contractsLoading}
                request={contractsRequest}
                response={contractsResponse}
                optionDisableCondition={[{ status: 2 }, { contract_type: 4 }]}
                infoText="Нельзя выбрать договоры, статус которых &lsquo;ЧЕРНОВИК&rsquo; и/или договоры саморекламы"
                clearable
                isRequired
                onChange={(newVal: ID | undefined) => {
                  if (newVal) {
                    const finalContract = contractsResponse?.data.results.find(
                      (item) => item?.id === newVal
                    );

                    if (finalContract) {
                      const newClient = finalContract.client;
                      if (newClient) {
                        setValue("client", newClient);
                        clearErrors("client");
                        shortTimeout(clientParticipantsRequest);
                      }

                      const newContractor = finalContract.contractor;
                      if (newContractor) {
                        setValue("contractor", newContractor);
                        clearErrors("contractor");
                        shortTimeout(contractorParticipantsRequest);
                      }

                      const newClientLegalForm = finalContract.extra_fields?.client?.legal_form;
                      if (newClientLegalForm) {
                        setValue("final_contract_client_legal_form", newClientLegalForm);
                      }

                      const isClientAdvertiserNew = finalContract.client_role === 1;
                      setIsClientAdvertiser(isClientAdvertiserNew);

                      if (isClientAdvertiserNew) {
                        if (newClient) {
                          setValue("client_initial", newClient);
                          clearErrors("client_initial");
                          shortTimeout(clientInitialParticipantsRequest);
                        }

                        setValue("initial_contract", newVal);
                        clearErrors("initial_contract");
                        shortTimeout(initialContractsRequest);
                      } else {
                        setValue("client_initial", undefined);
                        clearErrors("client_initial");
                        shortTimeout(clientInitialParticipantsRequest);

                        setValue("initial_contract", undefined);
                        clearErrors("initial_contract");
                        shortTimeout(initialContractsRequest);

                        setInitialContractsIds(finalContract.initial_contracts);
                      }
                    }
                  } else {
                    setSearchContractsValue(undefined);
                  }
                }}
              />
            </div>
          </div>

          <div className="row">
            <div className="col">
              <PredictiveSearchField
                name="client_initial"
                title="ИНН заказчика по изначальному договору (тип рекламодатель)"
                optionKey={["id", "name", "inn", "status"]}
                optionKeyLabels={["ID", "Название", "ИНН", "hidden"]}
                inputValue={searchClientInitialParticipantsValue}
                setInputValue={setSearchClientInitialParticipantsValue}
                control={control}
                errors={errors}
                errorText={getErrorText(errors, "client_initial", "Должно быть заполнено.")}
                isLoading={clientInitialParticipantsLoading}
                request={clientInitialParticipantsRequest}
                response={clientInitialParticipantsResponse}
                optionDisableCondition={{ status: 2 }}
                infoText="Нельзя выбрать контрагентов, статус которых &lsquo;ЧЕРНОВИК&rsquo;"
                clearable
                isRequired
                onChange={(newVal: ID | undefined) => {
                  if (!newVal) {
                    setSearchClientInitialParticipantsValue(undefined);
                  }
                  setSearchInitialContractsValue(undefined);
                  setValue("initial_contract", undefined);
                  clearErrors("initial_contract");
                  shortTimeout(initialContractsRequest);
                }}
                disabled={isClientAdvertiser}
              />
            </div>
          </div>

          <div className="row">
            <div className="col">
              <PredictiveSearchField
                name="initial_contract"
                title="Номер изначального договора"
                optionKey={[
                  "id",
                  "contract_number",
                  "extra_fields.client.name",
                  "extra_fields.contractor.name",
                  "status"
                ]}
                optionKeyLabels={["ID", "Номер контракта", "Заказчик", "Исполнитель", "hidden"]}
                inputValue={searchInitialContractsValue}
                setInputValue={setSearchInitialContractsValue}
                control={control}
                errors={errors}
                errorText={getErrorText(errors, "initial_contract", "Должно быть заполнено.")}
                isLoading={initialContractsLoading}
                request={initialContractsRequest}
                response={initialContractsResponse}
                optionDisableCondition={[{ status: 2 }, { contract_type: 4 }]}
                infoText="Нельзя выбрать договоры, статус которых &lsquo;ЧЕРНОВИК&rsquo;"
                clearable
                isRequired
                onChange={(newVal: ID | undefined) => {
                  if (newVal) {
                    const initialContract = initialContractsResponse?.data.results.find(
                      (item) => item?.id === newVal
                    );

                    if (initialContract) {
                      const newClient = initialContract.client;
                      if (newClient) {
                        setValue("client_initial", newClient);
                        clearErrors("client_initial");
                        shortTimeout(clientParticipantsRequest);
                      }

                      const newClientLegalForm = initialContract.extra_fields?.client?.legal_form;
                      if (newClientLegalForm) {
                        setValue("final_contract_client_legal_form", newClientLegalForm);
                      }
                    }
                  } else {
                    setSearchInitialContractsValue(undefined);
                  }
                }}
                disabled={isClientAdvertiser}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

import { TContractFilter } from "types/TContractFilter";
import { TSearchParamsRequired } from "types/TSearchParams";

export const defaultSearchParams: TSearchParamsRequired<TContractFilter> = {
  search: "",
  page: 0,
  page_size: 10,
  ordering: "-id",
  filter: {
    contract_type: [],
    ord_account: [],
    combined_status: [],
    client_role: [],
    contractor_role: [],
    client: [],
    contractor: [],
    participant: undefined
  }
};

export const defaultExportLink = "/api/v1/contract/export/xls";

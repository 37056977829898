import { ErrorMessage } from "Components/ErrorMessage";
import omit from "lodash/omit";
import { ID } from "models";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import { StateProps } from "reducers";
import { scrollToError } from "utils/scrollToError";
import { InvoiceForm } from "./components/form";
import { NoAllocationsFileModal } from "./components/noAllocationsFileModal";
import useEditInvoice from "./hooks/useEditInvoice";
import useLoadInvoice from "./hooks/useLoadInvoice";

type Props = {
  id: ID;
};

const InvoiceEditForm = ({ id }: Props) => {
  const invoiceLastSearch = useSelector((state: StateProps) => state.LastSearchParams.invoice);
  const [amountFromAllocationsFile, setAmountFromAllocationsFile] = useState<number | undefined>();
  const [isDraft, setIsDraft] = useState(false);
  const [isNoAllocationsFileModalOpen, setIsNoAllocationsFileModalOpen] = useState(false);
  const toggleNotAllocationsFileModal = () => setIsNoAllocationsFileModalOpen((prev) => !prev);

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setError,
    reset,
    setValue,
    resetField
  } = useForm();

  const { isLoading, error, initialParams, invoiceNumber } = useLoadInvoice(
    id,
    reset,
    setAmountFromAllocationsFile
  );

  const edit = useEditInvoice(id, invoiceLastSearch, watch, setError);

  const onEdit = () => {
    const status = isDraft ? 2 : 1;
    edit({
      id,
      invoice: {
        ...omit(watch(), "noNumber"),
        status
      }
    });
  };

  const onSubmit = handleSubmit(
    () => {
      // if (amountFromAllocationsFile && +watch("amount") !== amountFromAllocationsFile)
      //   createNotification(
      //     "error",
      //     `Указанная сумма ${watch(
      //       "amount"
      //     )}. Рассчитанная сумма ${amountFromAllocationsFile}. Значения должны совпадать`
      //   );
      // else {
      if (!watch("allocations_file") && !isDraft) {
        toggleNotAllocationsFileModal();
      } else {
        onEdit();
      }
      // }
    },
    (err) => {
      if (err) {
        scrollToError(err);
      }
    }
  );

  if (error) return <ErrorMessage error={error.message} isEmbedded />;

  const buttons = (
    <div className="d-flex gap-3 justify-content-between">
      <Button type="submit" color="primary" value={2} onClick={() => setIsDraft(true)}>
        Сохранить черновик
      </Button>
      <div className="d-flex gap-3">
        <Button href={`#/invoice/${invoiceLastSearch}`} tag="a" color="primary" outline>
          Отменить
        </Button>
        <Button
          type="submit"
          color="danger"
          value={1}
          disabled={!watch("allocations_file")}
          onClick={() => setIsDraft(false)}
        >
          Отправить в ЕРИР
        </Button>
      </div>
    </div>
  );

  return (
    <div>
      <h1>Редактирование акта {invoiceNumber}</h1>
      <InvoiceForm
        control={control}
        errors={errors}
        onSubmit={onSubmit}
        buttons={buttons}
        watch={watch}
        isLoading={isLoading}
        initialParams={initialParams}
        setAmountFromAllocationsFile={setAmountFromAllocationsFile}
        isDraft={isDraft}
        setValue={setValue}
        resetField={resetField}
        allocationsFileOld={
          watch("allocations_file") ? `/api/v1/invoice/${id}/allocation/` : undefined
        }
        statisticsFileOld={
          watch("creative_statistics_file") ? `/api/v1/invoice/${id}/statistics/` : undefined
        }
      />
      <NoAllocationsFileModal
        isOpen={isNoAllocationsFileModalOpen}
        toggle={toggleNotAllocationsFileModal}
        onContinue={onEdit}
      />
    </div>
  );
};

export default InvoiceEditForm;

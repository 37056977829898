import { CheckBoxField } from "ui/CheckBox";
import { InputField } from "ui/Input";
import { FieldProps } from "./types";

const ContractNumberField = ({
  control,
  errors,
  watch,
  contractLoading,
  resetField,
  isShown,
  isRequired
}: FieldProps) => {
  if (!isShown) return null;

  return (
    <div className="row align-items-center">
      <div className="col-sm-9">
        <InputField
          name="contract_number"
          title="Номер договора"
          maxLength={150}
          control={control}
          errors={errors}
          isRequired={!watch("noNumber") && (isRequired !== null ? isRequired : false)}
          isStared={!watch("noNumber")}
          disabled={!!watch("noNumber") || contractLoading}
        />
      </div>
      <div className="col-sm-3 mt-4">
        <CheckBoxField // только для доп соглашения
          name="noNumber" // не отправляется на сервер
          title="Нет номера"
          control={control}
          errors={errors}
          isChecked={!!watch("noNumber") || !!contractLoading}
          extraAction={() => {
            resetField("contract_number");
          }}
        />
      </div>
    </div>
  );
};

export default ContractNumberField;

import isArray from "lodash/isArray";
import { Fragment } from "react";
import { components, MultiValueGenericProps } from "react-select";

const MultiFieldMultiValueLabel = (
  { children, ...props }: MultiValueGenericProps<any>,
  optionKey: string | string[],
  optionKeyLabels?: string[]
) => {
  const parsed = JSON.parse(props.data.label);
  return (
    <components.MultiValueLabel {...props}>
      <div className="py-1 px-2">
        {isArray(optionKey) &&
          optionKey.map((op, index) =>
            optionKeyLabels && optionKeyLabels[index] === "hidden" ? (
              <Fragment key={op}></Fragment>
            ) : (
              <p key={op} className="mb-0">
                {optionKeyLabels ? (
                  <span className="text-secondary">{optionKeyLabels[index]}: </span>
                ) : null}
                <span>{parsed[op]}</span>
              </p>
            )
          )}
      </div>
    </components.MultiValueLabel>
  );
};

export default MultiFieldMultiValueLabel;
